import React from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faAngleDown,
  faTimes,
  faSearch,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import ETRModal from "../components/modals/ETRModal";
import CauseCodeModal from "../components/modals/CauseCodeModal";
import {
  JobDetailShort,
  getSDAJob,
  CrewStatusUpdateReq,
  updateCrewStatus,
} from "../lib/Api";
import Select, { SingleValue } from "react-select";
import * as Util from "../lib/Utility";
import { useThrottle } from "@react-hook/throttle";
import { useAppSelector, useAppDispatch } from "../lib/hooks";
import {
  getJobs,
  getJobsByCrews,
  setIncludeFollowUpJobs,
  setJobFilter,
  setShowCrewJobs,
  setSelectedJobPropertyFilter,
  setJobPropertyFilter,
} from "../features/jobSlice";
import {
  defaultJobPropertyFilters,
  FilterableJobProperty,
  getFilterableJobProperties,
} from "../lib/FilterableJobProperty";
import { useDebouncedCallback } from "use-debounce";
import CrewStatusModal from "../components/modals/CrewStatusModal";

//TODO: Lift this to redux
export const SORT_KEY_LOCAL_STORAGE_KEY = "OSA.SORT_KEY";
export const SORT_DIR_LOCAL_STORAGE_KEY = "OSA.SORT_DIR";

type SortKey = keyof JobDetailShort;

type SortDir = "asc" | "desc";

export type Job = {
  DISPATCH_GROUP: string;
  CAUSE_CODE: null | string;
  CREW_STATUS: string;
  JOB_STATUS: string;
  CREW: null | string;
  CIRCUIT: string;
  ETR: string;
  CUSTOMERS_OUT: number;
  JOB_CODE: string;
  JOB: string;
  REGION: string;
};

const sortByHeader = (
  key: SortKey | null,
  dir: SortDir,
  data: JobDetailShort[]
) => {
  if (key !== null) {
    switch (dir) {
      case "asc":
        const ascList = [...data].sort((a, b) => (a[key]! > b[key]! ? 1 : -1));
        return ascList;
      case "desc":
        const descList = [...data].sort((a, b) => (a[key]! < b[key]! ? 1 : -1));
        return descList;
    }
  } else {
    return data;
  }
};

const ListPage = (props: { bottom: boolean }) => {
  // state hooks
  const [jobs, setJobs] = React.useState<JobDetailShort[]>([]);
  const [filteredJobs, setFilteredJobs] = React.useState<JobDetailShort[]>([]);
  const [filterableJobProperties, setFilterableJobProperties] = React.useState(
    defaultJobPropertyFilters
  );
  const [loading, setLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(jobs.length > 50);
  const [page, setPage] = useThrottle(1, 1);
  const [accordianOpen, setAccordianOpen] = React.useState(false);

  // redux hooks
  const selectedJobPropertyFilter = useAppSelector(
    (state) => state.jobs.selectedJobPropertyFilter
  );
  const jobPropertyFilter = useAppSelector(
    (state) => state.jobs.jobPropertyFilter
  );
  const online = useAppSelector((state) => state.online);
  const allJobs = useAppSelector((state) => state.jobs.allJobs);
  const crewJobs = useAppSelector((state) => state.jobs.crewJobs);
  const outageJobs = useAppSelector((state) => state.jobs.outageJobs);
  const treeTrimJobs = useAppSelector((state) => state.jobs.treeTrimJobs);
  const nonOutageJobs = useAppSelector((state) => state.jobs.nonOutageJobs);
  const damageAssessmentJobs = useAppSelector(
    (state) => state.jobs.damageAssessJobs
  );
  const streetLightJobs = useAppSelector((state) => state.jobs.streetLightJobs);
  const role = useAppSelector((state) => state.role.value);
  const jobFilter = useAppSelector((state) => state.jobs.jobFilter);
  const serviceCenters = useAppSelector((state) => state.jobs.serviceCenter);
  const serviceCenterNames =
    serviceCenters === Util.ALL_SERVICE_CENTER
      ? Util.ALL_SERVICE_CENTER
      : serviceCenters.map((x) => x.value).join(", ");
  const showCrewJobs = useAppSelector((state) => state.jobs.showCrewJobs);
  const includeFollowUpJobs = useAppSelector(
    (state) => state.jobs.includeFollowUpJobs
  );
  const dispatch = useAppDispatch();

  const sortKeyInit = Util.getFromLocalStorageOrDefault(
    SORT_KEY_LOCAL_STORAGE_KEY,
    "customerOut"
  );
  const sortDirInit = Util.getFromLocalStorageOrDefault<SortDir>(
    SORT_DIR_LOCAL_STORAGE_KEY,
    "desc",
    ["asc", "desc"]
  );

  // local storage hooks
  const [sortKey, setSortKey] = React.useState<SortKey | null>(sortKeyInit);
  const [sortDir, setSortDir] = React.useState<SortDir>(sortDirInit);

  // effect hooks
  React.useEffect(() => {
    localStorage.setItem(SORT_KEY_LOCAL_STORAGE_KEY, JSON.stringify(sortKey));
    localStorage.setItem(SORT_DIR_LOCAL_STORAGE_KEY, JSON.stringify(sortDir));
  }, [sortKey, sortDir]);

  // need to reset for the case where the job filter changes which options are available.
  // React.useEffect(() => {
  //   dispatch(setSelectedJobPropertyFilter(filterableJobProperties[0]));
  // }, [filterableJobProperties, jobFilter]);

  // React.useEffect(() => {
  //   setJobPropertyFilter("");
  // }, [selectedJobPropertyFilter]);

  // detect and set the filterable options in the dropdown
  React.useEffect(() => {
    setFilterableJobProperties(
      getFilterableJobProperties(showCrewJobs, jobFilter, role)
    );
  }, [jobFilter, role, showCrewJobs]);

  React.useEffect(() => {
    if (online) {
      if (showCrewJobs) {
        dispatch(getJobsByCrews());
      } else {
        dispatch(getJobs());
      }
    }
  }, [
    online,
    serviceCenters,
    role,
    showCrewJobs,
    jobFilter,
    includeFollowUpJobs,
  ]);

  // filter the jobs by the selected drop down and filter input
  const debouncedJobPropertyFilter = useDebouncedCallback(
    (
      propertyFilter: string,
      selectedPropertyFilter: FilterableJobProperty,
      jobsToSearch: JobDetailShort[]
    ) => {
      if (propertyFilter.length > 0) {
        const filter = async () => {
          const newFJobs = jobsToSearch.filter((job) => {
            if (selectedPropertyFilter.value === "outageDate") {
              const outageDateToString = new Date(job.outageDate)
                .toLocaleString(...Util.dateFormat)
                .toUpperCase();
              return outageDateToString.includes(propertyFilter.toUpperCase());
            } else {
              return String(job[selectedPropertyFilter.value])
                ?.toUpperCase()
                ?.includes(propertyFilter.toUpperCase());
            }
          });
          return newFJobs;
        };
        filter().then(setFilteredJobs);
      } else {
        return setFilteredJobs(jobsToSearch);
      }
    },
    500
  );

  React.useEffect(() => {
    debouncedJobPropertyFilter(
      jobPropertyFilter,
      selectedJobPropertyFilter,
      jobs
    );
  }, [jobPropertyFilter, jobs, selectedJobPropertyFilter]);

  // set jobs based on the selected job type filter as well as handling crewJobs logic
  React.useEffect(() => {
    if (showCrewJobs) {
      setJobs(crewJobs);
    } else {
      switch (jobFilter) {
        case "All":
          setJobs(allJobs);
          break;

        case "DamageAssess":
          setJobs(damageAssessmentJobs);
          break;

        case "NonOutage":
          setJobs(nonOutageJobs);
          break;

        case "Outage":
          setJobs(outageJobs);
          break;

        case "TreeTrim":
          setJobs(treeTrimJobs);
          break;

        case "StreetLight":
          setJobs(streetLightJobs);
          break;
      }
    }
  }, [
    allJobs,
    crewJobs,
    damageAssessmentJobs,
    jobFilter,
    nonOutageJobs,
    outageJobs,
    showCrewJobs,
    treeTrimJobs,
    streetLightJobs,
    includeFollowUpJobs,
  ]);

  React.useEffect(() => {
    if (jobs.length > 50 * page) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [page, jobs]);

  React.useEffect(() => {
    setPage(1);
  }, [jobFilter, showCrewJobs, sortDir, sortKey, includeFollowUpJobs]);

  React.useEffect(() => {
    if (props.bottom && hasMore) {
      setPage((p) => p + 1);
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [props.bottom, hasMore]);

  const setSort = (key: SortKey) => {
    if (key === sortKey) {
      if (sortDir === "desc") {
        setSortDir("asc");
      } else if (sortDir === "asc") {
        setSortDir("desc");
        setSortKey(null);
      }
    } else {
      setSortKey(key);
    }
  };

  const buildHeader = () => {
    if (showCrewJobs) {
      if (role?.tt) {
        return (
          <TreeTrimHeaders
            setSort={setSort}
            sortKey={sortKey}
            sortDir={sortDir}
          />
        );
      } else {
        return (
          <DefaultHeaders
            setSort={setSort}
            sortKey={sortKey}
            sortDir={sortDir}
          />
        );
      }
    } else {
      switch (jobFilter) {
        case "DamageAssess":
          return (
            <DamageAssessHeaders
              setSort={setSort}
              sortKey={sortKey}
              sortDir={sortDir}
            />
          );
        case "NonOutage":
          return (
            <NonOutageHeaders
              setSort={setSort}
              sortKey={sortKey}
              sortDir={sortDir}
            />
          );
        case "Outage":
          return (
            <OutageHeaders
              setSort={setSort}
              sortKey={sortKey}
              sortDir={sortDir}
            />
          );
        case "TreeTrim":
          return (
            <TreeTrimHeaders
              setSort={setSort}
              sortKey={sortKey}
              sortDir={sortDir}
            />
          );
        default:
          return (
            <DefaultHeaders
              setSort={setSort}
              sortKey={sortKey}
              sortDir={sortDir}
            />
          );
      }
    }
  };

  const assignJobToCrew = async (crewId: string | undefined) => {
    if (crewId) {
      setLoading(true);
      await getSDAJob()
        .then((job) => {
          if (job.tag === "ok" && !Array.isArray(job.data)) {
            const req: CrewStatusUpdateReq = {
              msgId: job.data.jobId,
              jobId: job.data.jobId,
              workType: "GENERAL",
              action: "Assign",
            };

            updateCrewStatus(crewId, req).then((res) => {
              if (res.tag === "ok") {
                // job asigned, show it in temp list while we are waiting for response from WS
                job.data.crewId = crewId;
                setJobs([job.data, ...jobs]);
              } else {
                alert(
                  "An error has occurred in assigning a job, please try again."
                );
              }
            });
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <div className="text-black sm:mx-1">
        <div className="flex flex-wrap">
          <div className="text-2xl mt-1 mx-1 flex justify-start mb-1 sm:justify-center sm:mb-1 desktop:mb-2 w-full">
            {(role?.tag === "employee" || role?.supervisor) && (
              <Link to="/settings">
                <u className="text-dte-500">Service Centers:</u>{" "}
                {serviceCenterNames}
              </Link>
            )}
          </div>
          <div className="flex flex-col md:flex-row flex-wrap items-baseline w-full">
            {/*
            // Hide this while SDA chanegs not approved
            {role &&
              role.crews.length === 1 &&
              role.crews[0].toUpperCase().startsWith("SDA") && (
                <button
                  className="mr-0.5 bg-dte-500 py-0.5 px-1 text-white sm:mr-3 mb-0.5"
                  disabled={loading}
                  onClick={() => assignJobToCrew(role.crews[0])}
                >
                  {loading && (
                    <FontAwesomeIcon
                      className="mt-0.25 px-0.25"
                      icon={faSpinner}
                      spin
                    />
                  )}
                  Request Job
                </button>
              )} */}

            <div className="inline-flex flex-no-wrap items-baseline mb-1 mr-3 mx-1 sm:ml-0">
              <input
                type="checkbox"
                checked={showCrewJobs}
                disabled={
                  (role &&
                    (role.tag === "contractor" || role.crews.length === 0)) ??
                  false
                }
                onChange={() => dispatch(setShowCrewJobs(!showCrewJobs))}
              />
              <label className="mx-0.5 sm:text-xl">
                Jobs Assigned to My Crew
              </label>
            </div>
            <div className="hidden sm:flex flex-wrap">
              {role?.tag === "employee" && (
                <div className="flex items-center justify-start mb-1 mr-4">
                  <label className="w-6 whitespace-no-wrap">Job Type:</label>
                  <Select
                    isDisabled={showCrewJobs}
                    blurInputOnSelect={true}
                    styles={{
                      control: () => ({
                        border: "none",
                        display: "inline-flex",
                        flexWrap: "nowrap",
                        width: "100%",
                      }),
                    }}
                    className="border-2 w-18 border-dte-500 mr-1"
                    options={Util.JobTypeOptions}
                    value={{
                      value: jobFilter,
                      label:
                        Util.JobTypeOptions.find((x) => x.value === jobFilter)
                          ?.label ?? "",
                    }}
                    onChange={(e: any) => {
                      dispatch(setJobFilter(e.value));
                    }}
                  />
                  <div className="flex flex-no-wrap items-center justify-start">
                    <input
                      className=""
                      type="checkbox"
                      checked={includeFollowUpJobs}
                      disabled={showCrewJobs}
                      onChange={() =>
                        dispatch(setIncludeFollowUpJobs(!includeFollowUpJobs))
                      }
                    />
                    <label className="mx-0.5 text-xs">
                      Include Follow-Up Jobs
                    </label>
                  </div>
                </div>
              )}
              <EditFilterableJobProperty
                filter={jobPropertyFilter}
                options={filterableJobProperties}
                selected={selectedJobPropertyFilter}
              />
            </div>
            <div className="flex flex-col sm:hidden relative w-full">
              <button
                className="flex flex-no-wrap justify-between items-center hover:bg-gray-300 p-0.5 px-1"
                onClick={() => setAccordianOpen(!accordianOpen)}
              >
                <p className="text-xl">Search & Filter Jobs</p>
                <FontAwesomeIcon
                  icon={accordianOpen ? faTimes : faAngleDown}
                  size={"lg"}
                />
              </button>

              <div
                className={`top-0 left-0 px-1 pb-0.5 shadow-bottom w-full bg-white ${
                  accordianOpen ? "flex" : "hidden"
                } flex-col`}
              >
                {role?.tag === "employee" && (
                  <div className="flex flex-col mb-0.5 mr-4 w-full">
                    <label className="w-6 mb-0.5">Job Type:</label>
                    <Select
                      isDisabled={showCrewJobs}
                      blurInputOnSelect={true}
                      styles={{
                        control: () => ({
                          border: "none",
                          display: "inline-flex",
                          flexWrap: "nowrap",
                          width: "100%",
                        }),
                      }}
                      className="border-2 border-dte-500 mb-1"
                      options={Util.JobTypeOptions}
                      value={{
                        value: jobFilter,
                        label:
                          Util.JobTypeOptions.find((x) => x.value === jobFilter)
                            ?.label ?? "",
                      }}
                      onChange={(e: any) => {
                        dispatch(setJobFilter(e.value));
                      }}
                    />
                    <div className="flex flex-no-wrap items-center">
                      <input
                        className=""
                        type="checkbox"
                        checked={!includeFollowUpJobs}
                        disabled={showCrewJobs}
                        onChange={() =>
                          dispatch(setIncludeFollowUpJobs(!includeFollowUpJobs))
                        }
                      />
                      <label className="mx-0.5">Include Follow-Up Jobs</label>
                    </div>
                  </div>
                )}
                <EditFilterableJobProperty
                  filter={jobPropertyFilter}
                  options={filterableJobProperties}
                  selected={selectedJobPropertyFilter}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="w-full bg-dte-200 my-0.5 sm:hidden" />
        <div className="mx-1 sm:mx-0 max-w-full w-full overflow-x-auto">
          <table className="table-auto bg-white w-full text-black border mb-2">
            <thead className="bg-gray-200">
              <tr className="cursor-pointer">{buildHeader()}</tr>
            </thead>
            <tbody>
              {sortByHeader(
                sortKey,
                sortDir,
                jobPropertyFilter.length > 0 ? filteredJobs : jobs
              )
                .slice(0, 50 * page)
                .map((job, i) => (
                  <TableRow job={job} key={i} _key={job.jobId} />
                ))}
            </tbody>
          </table>
        </div>
        <div className="inline-flex justify-center text-center text-black w-full mb-1 font-bold">
          {hasMore ? (
            loading ? (
              <p>Loading...</p>
            ) : (
              <p>Scroll down to load more</p>
            )
          ) : (
            <p>End of list</p>
          )}
        </div>
      </div>
    </>
  );
};

export default ListPage;

const TableHeader = (props: {
  value: SortKey;
  label: String;
  onClick: (key: SortKey) => void;
  sortKey: SortKey | null;
  sortDir: SortDir;
}) => (
  <th
    scope="col"
    className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
    onClick={() => props.onClick(props.value)}
  >
    {props.label}

    <FontAwesomeIcon
      className={`ml-0.5 ${
        props.value === props.sortKey ? "opacity-100" : "opacity-0"
      }`}
      icon={props.sortDir === "asc" ? faCaretUp : faCaretDown}
    />
  </th>
);

const TableRow: React.FC<{ job: JobDetailShort; _key: number | string }> = ({
  job,
  _key,
}) => {
  const role = useAppSelector((state) => state.role.value);
  const jobFilter = useAppSelector((state) => state.jobs.jobFilter);
  const showCrewJobs = useAppSelector((state) => state.jobs.showCrewJobs);
  const dispatch = useAppDispatch();

  const afterSubmit = () => {
    if (showCrewJobs) {
      setTimeout(() => {
        dispatch(getJobsByCrews());
      }, 1500);
    } else {
      setTimeout(() => {
        dispatch(getJobs());
      }, 1500);
    }
  };

  if (showCrewJobs) {
    if (role?.tt) {
      return (
        <TreeTrimRow
          _key={_key}
          job={job}
          isValidDate={Util.isValidDate}
          afterSubmit={afterSubmit}
        />
      );
    } else {
      return <DefaultRow _key={_key} job={job} afterSubmit={afterSubmit} />;
    }
  } else {
    switch (jobFilter) {
      case "DamageAssess":
        return (
          <DamageAssessRow
            _key={_key}
            isValidDate={Util.isValidDate}
            afterSubmit={afterSubmit}
            job={job}
          />
        );
      case "NonOutage":
        return (
          <NonOutageRow
            _key={_key}
            isValidDate={Util.isValidDate}
            job={job}
            afterSubmit={afterSubmit}
          />
        );
      case "Outage":
        return <OutageRow _key={_key} job={job} afterSubmit={afterSubmit} />;
      case "TreeTrim":
        return (
          <TreeTrimRow
            _key={_key}
            job={job}
            isValidDate={Util.isValidDate}
            afterSubmit={afterSubmit}
          />
        );
      default:
        return <DefaultRow _key={_key} job={job} afterSubmit={afterSubmit} />;
    }
  }
};

const DamageAssessHeaders: React.FC<{
  setSort: (key: SortKey) => void;
  sortKey: SortKey | null;
  sortDir: SortDir;
}> = ({ setSort, sortKey, sortDir }) => {
  return (
    <>
      <TableHeader
        value="jobId"
        label="Job"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="address"
        label="Location"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="jobType"
        label="Type"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="jobSubType"
        label="Job Sub Type"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="circuit"
        label="Circuit"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="outageDate"
        label="Outage Date"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="customerOut"
        label="Cust. Out"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="causeCode"
        label="Cause Code"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="deviceType"
        label="Device Type"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="extent"
        label="Extent"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="callCode"
        label="Call Code"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
    </>
  );
};

const DefaultHeaders: React.FC<{
  setSort: (key: SortKey) => void;
  sortKey: SortKey | null;
  sortDir: SortDir;
}> = ({ setSort, sortKey, sortDir }) => {
  return (
    <>
      <TableHeader
        value="jobId"
        label="Job"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="address"
        label="Location"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="jobType"
        label="Type"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="jobSubType"
        label="Job Sub Type"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="circuit"
        label="Circuit"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="crewId"
        label="Crew"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="crewAssignmentStatus"
        label="Crew Assign."
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="customerOut"
        label="Cust. Out"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="etr"
        label="ETR"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="causeCode"
        label="Cause Code"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="deviceType"
        label="Device Type"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="extent"
        label="Extent"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="callCode"
        label="Call Code"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
    </>
  );
};

const NonOutageHeaders: React.FC<{
  setSort: (key: SortKey) => void;
  sortKey: SortKey | null;
  sortDir: SortDir;
}> = ({ setSort, sortKey, sortDir }) => {
  return (
    <>
      <TableHeader
        value="jobId"
        label="Job"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="address"
        label="Location"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="jobType"
        label="Type"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="jobSubType"
        label="Job Sub Type"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="circuit"
        label="Circuit"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="outageDate"
        label="Outage Date"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="crewId"
        label="Crew"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="crewAssignmentStatus"
        label="Crew Assign. Status"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="etr"
        label="ETR"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="causeCode"
        label="Cause Code"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="deviceType"
        label="Device Type"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="extent"
        label="Extent"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="callCode"
        label="Call Code"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
    </>
  );
};

const OutageHeaders: React.FC<{
  setSort: (key: SortKey) => void;
  sortKey: SortKey | null;
  sortDir: SortDir;
}> = ({ setSort, sortKey, sortDir }) => {
  return (
    <>
      <TableHeader
        value="jobId"
        label="Job"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="address"
        label="Location"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="jobType"
        label="Type"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="jobSubType"
        label="Job Sub Type"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="circuit"
        label="Circuit"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="crewId"
        label="Crew"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="crewAssignmentStatus"
        label="Crew Assign. Status"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="customerOut"
        label="Cust. Out"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="etr"
        label="ETR"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="causeCode"
        label="Cause Code"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="deviceType"
        label="Device Type"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="extent"
        label="Extent"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="callCode"
        label="Call Code"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
    </>
  );
};

const TreeTrimHeaders: React.FC<{
  setSort: (key: SortKey) => void;
  sortKey: SortKey | null;
  sortDir: SortDir;
}> = ({ setSort, sortKey, sortDir }) => {
  return (
    <>
      <TableHeader
        value="jobId"
        label="Job"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="address"
        label="Location"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="jobType"
        label="Type"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="jobSubType"
        label="Job Sub Type"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="circuit"
        label="Circuit"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="outageDate"
        label="Outage Date"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="crewId"
        label="Crew"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="callsource"
        label="Call Src."
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="deviceType"
        label="Device Type"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="extent"
        label="Extent"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
      <TableHeader
        value="callCode"
        label="Call Code"
        onClick={setSort}
        sortKey={sortKey}
        sortDir={sortDir}
      />
    </>
  );
};

const DefaultRow: React.FC<{
  _key: string | number;
  job: JobDetailShort;
  afterSubmit: () => void;
}> = ({ _key, job, afterSubmit }) => {
  return (
    <>
      <tr className="hover:bg-gray-200">
        <JobIDCell id={job.jobDisplayId} />
        <td className="border px-0.25 md:px-1 text-center text-sm">
          {job.address || "No address found"}
        </td>
        <td className="border px-0.25 md:px-1 text-center">{job.jobType}</td>
        <td className="border px-0.25 md:px-1 text-center">{job.jobSubType}</td>
        <td className="border px-0.25 md:px-1 text-center">
          {job.circuit !== "" ? job.circuit : "No Circuit"}
        </td>
        <td
          className={`border px-0.25 md:px-1 text-center ${
            job.crewId
              ? job.crewId === ""
                ? "text-red-600"
                : job.crewId
              : "text-red-600"
          }`}
        >
          {job.crewId ? (job.crewId === "" ? "N/A" : job.crewId) : "N/A"}
        </td>
        <td className="border text-center">
          <CrewStatusModal jobId={job.jobDisplayId} />
        </td>
        <td className="border px-0.25 md:px-1 text-center">
          {job.customerOut}
        </td>
        <td className="border text-center" onClick={(e) => e.stopPropagation()}>
          <ETRModal job={job} afterSubmit={afterSubmit} />
        </td>
        <td
          className="border text-center"
          onClick={(e) => {
            e.stopPropagation();
            afterSubmit();
          }}
        >
          <CauseCodeModal job={job} afterSubmit={afterSubmit} />
        </td>
        <td className="border px-0.25 md:px-1 text-center">
          {job.deviceSubType}
        </td>
        <td className="border px-0.25 md:px-1 text-center">{job.extent}</td>
        <td className="border px-0.25 md:px-1 text-center">{job.callCode}</td>
      </tr>
    </>
  );
};

const DamageAssessRow: React.FC<{
  _key: string | number;
  job: JobDetailShort;
  isValidDate: (date: string) => boolean;
  afterSubmit: () => void;
}> = ({ _key, isValidDate, afterSubmit, job }) => {
  return (
    <>
      <tr className=" hover:bg-gray-200">
        <JobIDCell id={job.jobDisplayId} />
        <td className="border px-0.25 md:px-1 text-center text-sm">
          {job.address || "No address found"}
        </td>
        <td className="border px-0.25 md:px-1 text-center">{job.jobType}</td>
        <td className="border px-0.25 md:px-1 text-center">{job.jobSubType}</td>
        <td className="border px-0.25 md:px-1 text-center">
          {job.circuit !== "" ? job.circuit : "No Circuit"}
        </td>
        <td className="border px-0.25 md:px-1 text-center">
          {isValidDate(job.outageDate)
            ? new Date(job.outageDate).toLocaleString(...Util.dateFormat)
            : "Invalid Date"}
        </td>
        <td className="border px-0.25 md:px-1 text-center">
          {job.customerOut}
        </td>
        <td
          className="border text-center"
          onClick={(e) => {
            e.stopPropagation();
            afterSubmit();
          }}
        >
          <CauseCodeModal job={job} afterSubmit={afterSubmit} />
        </td>
        <td className="border px-0.25 md:px-1 text-center">
          {job.deviceSubType}
        </td>
        <td className="border px-0.25 md:px-1 text-center">{job.extent}</td>
        <td className="border px-0.25 md:px-1 text-center">{job.callCode}</td>
      </tr>
    </>
  );
};

const NonOutageRow: React.FC<{
  _key: string | number;
  job: JobDetailShort;
  isValidDate: (date: string) => boolean;
  afterSubmit: () => void;
}> = ({ _key, isValidDate, job, afterSubmit }) => {
  return (
    <>
      <tr className="hover:bg-gray-200">
        <JobIDCell id={job.jobDisplayId} />
        <td className="border px-0.25 md:px-1 text-center text-sm">
          {job.address || "No address found"}
        </td>
        <td className="border px-0.25 md:px-1 text-center">{job.jobType}</td>
        <td className="border px-0.25 md:px-1 text-center">{job.jobSubType}</td>
        <td className="border px-0.25 md:px-1 text-center">
          {job.circuit !== "" ? job.circuit : "No Circuit"}
        </td>
        <td className="border px-0.25 md:px-1 text-center">
          {isValidDate(job.outageDate)
            ? new Date(job.outageDate).toLocaleString(...Util.dateFormat)
            : "Invalid Date"}
        </td>
        <td
          className={`border px-0.25 md:px-1 text-center ${
            job.crewId
              ? job.crewId === ""
                ? "text-red-600"
                : job.crewId
              : "text-red-600"
          }`}
        >
          {job.crewId ? (job.crewId === "" ? "N/A" : job.crewId) : "N/A"}
        </td>
        <td className="border text-center">
          <CrewStatusModal jobId={job.jobDisplayId} />
        </td>
        <td className="border text-center" onClick={(e) => e.stopPropagation()}>
          <ETRModal job={job} afterSubmit={afterSubmit} />
        </td>
        <td
          className="border text-center"
          onClick={(e) => {
            e.stopPropagation();
            afterSubmit();
          }}
        >
          <CauseCodeModal job={job} afterSubmit={afterSubmit} />
        </td>
        <td className="border px-0.25 md:px-1 text-center">
          {job.deviceSubType}
        </td>
        <td className="border px-0.25 md:px-1 text-center">{job.extent}</td>
        <td className="border px-0.25 md:px-1 text-center">{job.callCode}</td>
      </tr>
    </>
  );
};
const OutageRow: React.FC<{
  _key: string | number;
  job: JobDetailShort;
  afterSubmit: () => void;
}> = ({ _key, job, afterSubmit }) => {
  return (
    <>
      <tr className="hover:bg-gray-200">
        <JobIDCell id={job.jobDisplayId} />
        <td className="border px-0.25 md:px-1 text-center text-sm">
          {job.address || "No address found"}
        </td>
        <td className="border px-0.25 md:px-1 text-center">{job.jobType}</td>
        <td className="border px-0.25 md:px-1 text-center">{job.jobSubType}</td>
        <td className="border px-0.25 md:px-1 text-center">
          {job.circuit !== "" ? job.circuit : "No Circuit"}
        </td>
        <td
          className={`border px-0.25 md:px-1 text-center ${
            job.crewId
              ? job.crewId === ""
                ? "text-red-600"
                : job.crewId
              : "text-red-600"
          }`}
        >
          {job.crewId ? (job.crewId === "" ? "N/A" : job.crewId) : "N/A"}
        </td>
        <td className="border text-center">
          <CrewStatusModal jobId={job.jobDisplayId} />
        </td>
        <td className="border px-0.25 md:px-1 text-center">
          {job.customerOut}
        </td>
        <td className="border text-center" onClick={(e) => e.stopPropagation()}>
          <ETRModal job={job} afterSubmit={afterSubmit} />
        </td>
        <td
          className="border text-center"
          onClick={(e) => {
            e.stopPropagation();
            afterSubmit();
          }}
        >
          <CauseCodeModal job={job} afterSubmit={afterSubmit} />
        </td>
        <td className="border px-0.25 md:px-1 text-center">
          {job.deviceSubType}
        </td>
        <td className="border px-0.25 md:px-1 text-center">{job.extent}</td>
        <td className="border px-0.25 md:px-1 text-center">{job.callCode}</td>
      </tr>
    </>
  );
};

const TreeTrimRow: React.FC<{
  _key: string | number;
  job: JobDetailShort;
  isValidDate: (date: string) => boolean;
  afterSubmit: () => void;
}> = ({ _key, job, isValidDate, afterSubmit }) => {
  return (
    <>
      <tr className="hover:bg-gray-200">
        <JobIDCell id={job.jobDisplayId} />
        <td className="border px-0.25 md:px-1 text-center text-sm">
          {job.address || "No address found"}
        </td>
        <td className="border px-0.25 md:px-1 text-center">{job.jobType}</td>
        <td className="border px-0.25 md:px-1 text-center">{job.jobSubType}</td>
        <td className="border px-0.25 md:px-1 text-center">
          {job.circuit !== "" ? job.circuit : "No Circuit"}
        </td>
        <td className="border px-0.25 md:px-1 text-center">
          {isValidDate(job.outageDate)
            ? new Date(job.outageDate).toLocaleString(...Util.dateFormat)
            : "Invalid Date"}
        </td>
        <td
          className={`border px-0.25 md:px-1 text-center ${
            job.crewId
              ? job.crewId === ""
                ? "text-red-600"
                : job.crewId
              : "text-red-600"
          }`}
        >
          {job.crewId ? (job.crewId === "" ? "N/A" : job.crewId) : "N/A"}
        </td>

        <td className="border px-0.25 md:px-1 text-center">{job.callsource}</td>
        <td className="border px-0.25 md:px-1 text-center">
          {job.deviceSubType}
        </td>
        <td className="border px-0.25 md:px-1 text-center">{job.extent}</td>
        <td className="border px-0.25 md:px-1 text-center">{job.callCode}</td>
      </tr>
    </>
  );
};

const JobIDCell = (props: { id: string }) => {
  const history = useHistory();
  return (
    <td
      className="border py-0.5 text-center font-bold hover:bg-gray-400 cursor-pointer"
      onClick={() => history.push("/details/" + props.id)}
    >
      {props.id}
    </td>
  );
};

const EditFilterableJobProperty = (props: {
  selected: FilterableJobProperty;
  options: FilterableJobProperty[];
  filter: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleSelectChange = (e: SingleValue<FilterableJobProperty>): void => {
    if (e) {
      dispatch(setSelectedJobPropertyFilter(e));
    }
  };

  const handleFilterChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    dispatch(setJobPropertyFilter(e.currentTarget.value));
  };

  return (
    <div className="flex sm:items-center mb-0.5 sm:mb-1 sm:flex-row flex-col">
      <label className="mb-0.5 sm:mb-0 sm:w-6 whitespace-no-wrap">
        Search By:
      </label>
      <Select
        blurInputOnSelect={true}
        className="w-12 border-2 border-dte-500 mr-0.5 mb-0.5 sm:mb-0 "
        options={props.options}
        value={props.selected}
        onChange={handleSelectChange}
        styles={{
          control: () => ({
            border: "none",
            display: "inline-flex",
            flexWrap: "nowrap",
            width: "100%",
          }),
        }}
      />
      <div className="flex items-center">
        <input
          className={"w-full md:w-20 border-2 border-dte-500 h-2.5 pl-1"}
          placeholder="Search"
          value={props.filter}
          onChange={handleFilterChange}
        />
        <span
          className="flex items-center justify-center"
          style={{ width: "40px", height: "40px", backgroundColor: "#3464A7" }}
        >
          <FontAwesomeIcon
            style={{ color: "white" }}
            className={"float-right"}
            icon={faSearch}
          />
        </span>
      </div>
    </div>
  );
};
