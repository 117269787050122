import React from "react";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as Api from "../../lib/Api";
import { useAppSelector, useAppDispatch } from "../../lib/hooks";
import { setLoading } from "../../features/jobSlice";
import * as Util from "../../lib/Utility";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import { trackLocationData } from "../../lib/api/trackLocationApi";
import { TextAreaControl } from "../controls/TextAreaControl";
import { useHistory } from "react-router-dom";

const ACKNOWLEDGED = "Acknowledged";
const EN_ROUTE = "En-Route";
const ARRIVED = "Arrived";
const DISPATCHED = "Dispatched";
const COMPLETED = "Completed";
const CANCELED = "Canceled";
const DISPATCHED_SUCCESSFUL = "Dispatch Successful";

const ERROR = "Error";

const buildCrewAssignmentReq = (
  jobId: string,
  name: string,
  crewAssignStatus: string,
  eta?: string,
  disp?: string[],
  notes?: string
): Api.CrewAssignmentStatusUpdateReq => ({
  msgId: jobId,
  jobId: jobId,
  comments: `${name} updated Crew Assignment Status to ${crewAssignStatus}${
    disp ? ` with disposition ${disp}` : ""
  } ${notes !== "" ? `Notes: ${notes}` : ""}`,
  crewAssignmentStatus: crewAssignStatus,
  eta: eta,
  disposition: disp,
});

const CrewAssignStatusModal = (props: {
  job: Api.JobDetail;
  afterSubmit: Function;
  crewId: string;
  crewType: string;
  crewsOnJob: number;
  disabled?: boolean;
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedCrewAssignStatus, setSelectedCrewAssignStatus] =
    React.useState<string | null>(props.job.crewAssignmentStatus || null);
  const [selectedETA, setSelectedETA] = React.useState<Date | null>(null);
  const role = useAppSelector((state) => state.role.value);
  const [crewStatus, setCrewStatus] = React.useState("Loading");
  const dispatch = useAppDispatch();
  const [disp, setDisp] = React.useState<string | null>(null);
  const [comment, setComment] = React.useState("");
  const history = useHistory();

  React.useEffect(() => {
    setSelectedCrewAssignStatus(null);
  }, [props.job]);

  React.useEffect(() => {
    if (modalOpen) {
      Api.getCrewStatusByCrewList([props.crewId])
        .then((res) => {
          if (res.tag === "ok") {
            const cs =
              res.data.find((x) => x.jobId === props.job.jobId)?.crewStatus ??
              "Error";
            setCrewStatus(cs);
          } else {
            setCrewStatus(ERROR);
          }
        })
        .catch((err) => {
          console.log(err);
          setCrewStatus(ERROR);
        });
    }
  }, [modalOpen]);

  React.useEffect(() => {
    setSelectedCrewAssignStatus(null);
  }, [props.job]);

  // const addComment = async (comment: string) => {
  //   await trackLocationData("updateJob", props.job.jobId, role!);
  //   await Api.updateJob(props.job.jobId, {
  //     msgId: props.job.jobId,
  //     jobComments: comment,
  //   });
  // };

  const handleSubmit = async () => {
    const eta = selectedETA?.toISOString();
    if (
      ((props.crewId !== "" && props.crewId !== null) ||
        (props.job.crewId !== "" && props.job.crewId !== null)) &&
      selectedCrewAssignStatus
    ) {
      dispatch(setLoading(true));

      const crewId = props.crewId || props.job.crewId;
      const name = role?.name ?? "";
      const jobId = props.job.jobId;
      const req = buildCrewAssignmentReq(
        jobId,
        `${crewId} - ${name}`,
        selectedCrewAssignStatus,
        eta,
        disp ? [disp] : undefined,
        comment
      );
      const res = await Api.updateCrewAssignmentStatus(crewId, req);
      if (res.tag === "ok") {
        props.afterSubmit();
        if (
          (props.crewType === "DAMAGE ASSESSMENT" ||
            props.crewType === "STORM SUPPORT") &&
          props.crewsOnJob === 1 &&
          (props.job.jobType === "OUTAGE" ||
            (props.job.jobType === "SERVICE DOWN NO LIGHTS" &&
              props.job.callCode === "SDXL") ||
            (props.job.jobType === "NON-OUTAGE" &&
              props.job.callCode === "SDLTSOK")) &&
          selectedCrewAssignStatus === COMPLETED &&
          (disp === "OK ON ARRIVAL" || disp === "FOREIGN WIRE") &&
          role?.tag === "employee" &&
          role.crews.includes(crewId)
        ) {
          // Auto close outages if no need to do anything on it and it was last crew
          let jobUpdate: Api.JobUpdateReq;
          if (disp === "OK ON ARRIVAL") {
            jobUpdate = {
              msgId: props.job.jobId,
              jobStatus: {
                jobStatus: "Completed",
                comments: `${crewId} - ${role?.name} closed job ${props.job.jobDisplayId}. ${comment}`,
              },
              causeCode: {
                causeCode: "NO ISSUES FOUND",
                comments: `${crewId} - ${role?.name} updated Cause Code to NO ISSUES FOUND`,
              },
              materialAffected: ["NO MATERIAL AFFECTED"],
            };
          } else {
            // FOREIGN WIRE
            jobUpdate = {
              msgId: props.job.jobId,
              jobStatus: {
                jobStatus: "Completed",
                comments: `${crewId} - ${role?.name} closed job ${props.job.jobDisplayId}. ${comment}`,
              },
              causeCode: {
                causeCode: "OTHER UTILITY EQUIP",
                comments: `${crewId} - ${role?.name} updated Cause Code to OTHER UTILITY EQUIPMENT`,
              },
              materialAffected: ["NO MATERIAL AFFECTED"],
            };
          }
          const autoclose = await Api.updateJob(props.job.jobId, jobUpdate);
          if (autoclose.tag === "ok") {
            alert("Job closed successfully");
            history.push("/list");
          } else {
            alert(
              "Job Close failed, check your internet connection. You can resend the request through the Drafts page"
            );
          }
        }
      } else {
        alert(
          "Crew assignment update failed, check your internet connection. You can resend the request through the Drafts page"
        );
      }
      dispatch(setLoading(false));
    }
  };

  const label =
    props.job.crewAssignmentStatus === ""
      ? "N/A"
      : props.job.crewAssignmentStatus;

  return (
    <>
      <Popup
        closeOnDocumentClick={true}
        modal={true}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setDisp("");
        }}
        onOpen={() => {
          setModalOpen(true);
          setDisp("");
        }}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default" }}
        disabled={props.disabled || props.job.crewId === ""}
        className="responsive-modal"
        trigger={
          <button
            className={`inline-flex flex-no-wrap whitespace-no-wrap items-center justify-center ${
              !props.disabled && "cursor-pointer"
            } ${label === "N/A" ? "text-red-600" : "text-green-600"}`}
          >
            {label}
            {!props.disabled && props.crewId !== "" && (
              <FontAwesomeIcon className="ml-1" icon={faEdit} />
            )}
          </button>
        }
      >
        {(close: () => void) => (
          <div className="text-black py-1">
            <p className="mb-1">
              Select the Crew Status <span className="text-red-600">*</span>
            </p>
            <div className="flex flex-col">
              {(crewStatus === DISPATCHED ||
                crewStatus === DISPATCHED_SUCCESSFUL) && (
                <CrewAssignmentButton
                  status={ACKNOWLEDGED}
                  selectedStatus={selectedCrewAssignStatus ?? ""}
                  setSelectedStatus={setSelectedCrewAssignStatus}
                />
              )}
              {crewStatus === ACKNOWLEDGED && (
                <>
                  <CrewAssignmentButton
                    status={EN_ROUTE}
                    selectedStatus={selectedCrewAssignStatus ?? ""}
                    setSelectedStatus={setSelectedCrewAssignStatus}
                  />
                  <div>
                    <p className="mb-1">Set the ETA</p>
                    <DatePicker
                      selected={selectedETA}
                      showTimeSelect={true}
                      timeFormat="hh:mm a"
                      timeIntervals={15}
                      onChange={(d: Date) => setSelectedETA(d)}
                      inline
                    />
                    <p className="text-lg font-bold py-0.5">
                      {selectedETA
                        ? new Date(selectedETA).toLocaleString(
                            ...Util.dateFormat
                          )
                        : "No ETA"}
                    </p>
                  </div>
                </>
              )}
              {crewStatus === EN_ROUTE && (
                <CrewAssignmentButton
                  status={ARRIVED}
                  selectedStatus={selectedCrewAssignStatus ?? ""}
                  setSelectedStatus={setSelectedCrewAssignStatus}
                />
              )}
              {crewStatus === ARRIVED && (
                <>
                  <CrewAssignmentButton
                    status={COMPLETED}
                    selectedStatus={selectedCrewAssignStatus ?? ""}
                    setSelectedStatus={setSelectedCrewAssignStatus}
                  />

                  {selectedCrewAssignStatus && (
                    <>
                      <p className="mb-1">
                        Select the Crew disposition:{" "}
                        <span className="text-red-600">*</span>
                      </p>

                      {props.crewType === "TREE TRIM" &&
                        Util.TTdispositionCodes.map((code) => (
                          <button
                            className={`${
                              disp === code.value
                                ? "bg-white border border-dte-500 text-dte-500"
                                : "border border-dte-500 bg-dte-500 text-white"
                            } mx-0.5 my-0.5 py-0.5 px-0.75`}
                            onClick={() => setDisp(code.value)}
                            key={code.value}
                          >
                            {code.label}
                          </button>
                        ))}

                      {Util.dispositionCodes.map((code) => (
                        <button
                          className={`${
                            disp === code.value
                              ? "bg-white border border-dte-500 text-dte-500"
                              : "border border-dte-500 bg-dte-500 text-white"
                          } mx-0.5 my-0.5 py-0.5 px-0.75`}
                          onClick={() => setDisp(code.value)}
                          key={code.value}
                        >
                          {code.label}
                        </button>
                      ))}

                      <TextAreaControl
                        label="Note"
                        value={comment}
                        setValue={(value) => setComment(value)}
                        placeholder="Enter notes..."
                        required={true}
                      />

                      {(props.crewType === "DAMAGE ASSESSMENT" ||
                        props.crewType === "STORM SUPPORT") &&
                        props.crewsOnJob === 1 &&
                        (props.job.jobType === "OUTAGE" ||
                          (props.job.jobType === "SERVICE DOWN NO LIGHTS" &&
                            props.job.callCode === "SDXL") ||
                          (props.job.jobType === "NON-OUTAGE" &&
                            props.job.callCode === "SDLTSOK")) &&
                        role?.tag === "employee" &&
                        role.crews.includes(props.crewId) && (
                          <>
                            {disp === "OK ON ARRIVAL" && (
                              <>
                                <p className="my-1">
                                  <b>Job will be closed</b>
                                </p>
                                <p>Cause Code: NO ISSUES FOUND</p>
                                <p>Materials Affected: NO MATERIAL AFFECTED</p>
                              </>
                            )}
                            {disp === "FOREIGN WIRE" && (
                              <>
                                <p className="my-1">
                                  <b>Job will be closed</b>
                                </p>
                                <p>Cause Code: OTHER UTILITY EQUIPMENT</p>
                                <p>Materials Affected: NO MATERIAL AFFECTED</p>
                              </>
                            )}
                          </>
                        )}
                    </>
                  )}
                </>
              )}
              {crewStatus === COMPLETED && <p>Crew completed work</p>}
              {crewStatus === CANCELED && <p>Crew was canceled</p>}

              {crewStatus === ERROR && <p>Error fetching status</p>}
            </div>
            <div className="w-full inline-flex flex-no-wrap justify-center items-center my-1">
              {props.job.crewAssignmentStatus !== COMPLETED && (
                <button
                  className="px-1 py-0.5 bg-gray-200 font-bold mr-1"
                  onClick={() => {
                    handleSubmit();
                    close();
                  }}
                  disabled={
                    !selectedCrewAssignStatus ||
                    (selectedCrewAssignStatus === EN_ROUTE && !selectedETA) ||
                    (selectedCrewAssignStatus === COMPLETED &&
                      !(disp && comment)) ||
                    props.job.crewAssignmentStatus === COMPLETED
                  }
                >
                  Submit
                </button>
              )}

              <button
                className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                onClick={() => {
                  setSelectedCrewAssignStatus(null);
                  setDisp(null);
                  setComment("");
                  close();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default CrewAssignStatusModal;

const CrewAssignmentButton = (props: {
  status: string;
  selectedStatus: string;
  setSelectedStatus: Function;
}) => (
  <button
    className={`${
      props.selectedStatus === props.status
        ? "bg-white border border-dte-500 text-dte-500"
        : "border border-dte-500 bg-dte-500 text-white"
    } mx-0.5 my-0.5 py-0.5 px-0.75`}
    onClick={() => props.setSelectedStatus(props.status)}
    key={props.status}
  >
    {props.status}
  </button>
);
