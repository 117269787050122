import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { getTreeTrimOutageInfo } from "../../features/treeTrimSlice";
import { JobDetail, JobDetailShort } from "../../lib/Api";
import { trackLocationData } from "../../lib/api/trackLocationApi";
import { saveTreeTrimOutageInfo } from "../../lib/api/treeTrimApi";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import {
  initTreeTrimOutageInfo,
  TreeTrimOutageInfo,
  TreeTrimOutageInfoKey,
  validateOutageInfo,
} from "../../lib/models/TreeTrimOutageInfo";
import TreeTrimOutageInfoForm from "./TreeTrimOutageInfoForm";

const TreeTrimOutageInfoModal: React.FC<{
  job: JobDetail | JobDetailShort;
}> = ({ job }) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);
  const existingOutageInfo = useAppSelector(
    (state) => state.treeTrim.outageInfo
  );

  const [formActivities, setFormActivities] =
    useState<TreeTrimOutageInfo[]>(existingOutageInfo);
  const [removedTreeTrimIds, setRemovedTreeTrimIds] = useState<string[]>([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<
    TreeTrimOutageInfoKey[][]
  >([]);

  const add = () => {
    const outageInfo = initTreeTrimOutageInfo();
    outageInfo.jobId = job.jobId;
    outageInfo.jobType = job.jobType;
    outageInfo.jobSubType = job.jobSubType;
    outageInfo.circuit = job.circuit;
    outageInfo.jobCreateDate = job.outageDate;
    outageInfo.createDate = new Date().toISOString();
    outageInfo.createUser = role?.id || "";

    const activities = [...formActivities, outageInfo];
    setFormActivities(activities);
  };

  const remove = (index: number) => {
    const activities = [...formActivities];
    const [removedActivity] = activities.splice(index, 1);
    setFormActivities(activities);
    if (removedActivity.id) {
      setRemovedTreeTrimIds((prev) => [...prev, removedActivity.id]);
    }
  };

  const updateActivity = (
    treeTrimActivity: TreeTrimOutageInfo,
    index: number
  ) => {
    const existingItems = [...formActivities];

    setFormActivities([
      ...existingItems.slice(0, index),
      treeTrimActivity,
      ...existingItems.slice(index + 1),
    ]);
  };

  const isError = (errorFields: TreeTrimOutageInfoKey[][]): boolean => {
    //if none of the forms have errors there will be no arrays of errors; index will be -1
    return !(errorFields.findIndex((ve) => ve.length > 0) === -1);
  };

  const disableSubmit = (): boolean => {
    return (
      isSubmitting || formActivities.length === 0 || isError(validationErrors)
    );
  };

  const validate = (): TreeTrimOutageInfoKey[][] => {
    setValidationErrors([]);
    const errors: TreeTrimOutageInfoKey[][] = [];
    for (let i = 0; i < formActivities.length; i++) {
      errors.push([]);
      const activityErrors = validateOutageInfo(formActivities[i]);
      errors[i] = activityErrors;
    }
    setValidationErrors(errors);
    return errors;
  };

  const submit = async (close: () => void) => {
    setIsDirty(true);
    setIsSubmitting(true);

    const preSubmitErrors = validate();

    if (isError(preSubmitErrors)) {
      setIsSubmitting(false);
      return;
    }

    await trackLocationData("saveTreeTrimOutageInfo", job.jobId, role!);

    const results = await saveTreeTrimOutageInfo(
      formActivities,
      removedTreeTrimIds
    );

    const apiErrors = results
      .map((r) => {
        if (r?.tag === "err") {
          return r.err.response.data.message;
        }
        return "";
      })
      .filter((err) => err !== "");

    if (apiErrors.length) {
      apiErrors.forEach((err) => console.error(err));
      alert("Error saving tree trim activities");
    } else {
      dispatch(getTreeTrimOutageInfo(job.jobId));
      close();
      setIsDirty(false);
    }

    setIsSubmitting(false);

    return;
  };

  const cancelForm = async () => {
    setFormActivities(existingOutageInfo);
    setValidationErrors([]);
    setIsSubmitting(false);
    setIsDirty(false);
  };

  useEffect(() => {
    setFormActivities(existingOutageInfo);
  }, [existingOutageInfo]);

  useEffect(() => {
    if (isDirty) {
      setValidationErrors(validate());
    }
  }, [formActivities]);

  return (
    <Popup
      closeOnDocumentClick={false}
      modal={true}
      overlayStyle={{ cursor: "default" }}
      contentStyle={{ cursor: "default" }}
      className="responsive-modal"
      onOpen={() => {
        if (!existingOutageInfo.length) {
          add();
        }
      }}
      trigger={
        <button className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white">
          Add Entry
        </button>
      }
    >
      {(close: () => void) => (
        <div className="text-black relative flex flex-col min-h-modal">
          <div className="flex flex-wrap justify-center px-2 mb-5 overflow-auto">
            <h1 className="w-full text-2xl p-2.5 text-center">
              Outage Information
            </h1>
            <div className="w-3/4">
              {formActivities.map((tt, index) => (
                <React.Fragment key={index}>
                  <div className="flex w-full justify-between">
                    <div className="flex-inline font-bold text-left mb-1">
                      Entry {index + 1}
                    </div>
                    <div className="flex-inline text-right mb-1">
                      <button
                        onClick={() => {
                          remove(index);
                        }}
                        className="text-red-600 underline"
                      >
                        Remove Activity
                      </button>
                    </div>
                  </div>
                  <TreeTrimOutageInfoForm
                    treeTrimOutageInfo={tt}
                    onUpdate={(value) => {
                      updateActivity(value, index);
                    }}
                    errors={validationErrors[index]}
                  />
                </React.Fragment>
              ))}
              <div className="w-full mb-1">
                <button
                  onClick={() => add()}
                  className="border border-solid border-dte-600 py-0.5 px-1 text-dte-600 w-full"
                >
                  + Add Entry
                </button>
              </div>
              <div className="flex justify-center">
                <button
                  disabled={disableSubmit()}
                  onClick={async () => {
                    await submit(close);
                  }}
                  className="bg-dte-500 border border-solid border-gray-600 py-0.5 px-1 text-white mx-0.5
                                    disabled:bg-gray-300 disabled:text-gray-500 disabled:border-gray-200"
                >
                  Submit
                </button>
                <button
                  className="px-1 py-0.5 bg-gray-600 text-white border border-solid border-gray-600 mx-0.5"
                  onClick={() => {
                    cancelForm();
                    close();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default TreeTrimOutageInfoModal;
