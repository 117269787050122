import * as msal from "msal";

const clientId = process.env.REACT_APP_MS_CLIENT_ID ?? "";

const logger = new msal.Logger(
  (logLevel, message, containsPii) => {
    console.log("[MSAL]", message);
  },
  {
    level:
      process.env.NODE_ENV === "development"
        ? msal.LogLevel.Verbose
        : msal.LogLevel.Error,
    piiLoggingEnabled: false,
  }
);

const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ") > -1;
  const msie11 = ua.indexOf("Trident/") > -1;
  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  return msie || msie11;
};

// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
const msalInstance = new msal.UserAgentApplication({
  auth: {
    clientId: clientId,
    authority:
      "https://login.microsoftonline.com/8e61d5fe-7749-4e76-88ee-6d8799ae8143",
    validateAuthority: true,
    postLogoutRedirectUri:
      window.location.hostname === "localhost"
        ? "http://localhost:3000/"
        : process.env.REACT_APP_URL ?? "",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE(),
  },
  // Enable logging of MSAL events for easier troubleshooting.
  system: {
    navigateFrameWait: 500,
    logger: logger,
  },
});

const authCallback: msal.authResponseCallback = (err, res) => {
  console.log(err, res);
};

msalInstance.handleRedirectCallback(authCallback);

export default msalInstance;

export const userRequest: msal.AuthenticationParameters = {
  scopes: ["openid", "offline_access"],
  redirectUri:
    window.location.hostname === "localhost"
      ? "http://localhost:3000/"
      : process.env.REACT_APP_URL ?? "",
};

export const renewRequest: msal.AuthenticationParameters = {
  scopes: [clientId],
  prompt: "none",
  redirectUri:
    window.location.hostname === "localhost"
      ? "http://localhost:3000/"
      : process.env.REACT_APP_URL ?? "",
};

export const getToken = async () => {
  try {
    const tokenRes = await msalInstance.acquireTokenSilent(renewRequest);
    return `${tokenRes.idToken.rawIdToken}`;
  } catch (err) {
    //If this fails, not entirely sure what happens, should just logout the user I think.
    //Possible/probable it needs to be wrapped in its own try catch
    msalInstance.acquireTokenRedirect(renewRequest);
    return "bad-token";
  }
};

/*{
    loginType: LoginType.Redirect,
    // When a token is refreshed it will be done by loading a page in an iframe.
    // Rather than reloading the same page, we can point to an empty html file which will prevent
    // site resources from being loaded twice.
    tokenRefreshUri: window.location.origin + "/auth.html",
  }
*/
