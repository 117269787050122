import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
//@ts-ignore
import allSettled from "@ungap/promise-all-settled";
import { store } from "./ReduxStore";
import { Provider } from "react-redux";

if (!Promise.allSettled) {
  Promise.allSettled = allSettled;
}

//@ts-ignore
window.dojoConfig = {
  async: true,
  has: { "csp-restrictions": true },
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
