import axios, { AxiosRequestConfig } from "axios";

export type Result<T, E = any> =
  | { tag: "ok"; data: T }
  | { tag: "err"; err: E };

const get = async <T>(
  url: string,
  config?: AxiosRequestConfig,
  logErr: boolean = true
): Promise<Result<T>> => {
  try {
    const res = await axios.get<T>(url, config);
    return { tag: "ok", data: res.data };
  } catch (err) {
    logErr && console.log(err);
    return { tag: "err", err: err };
  }
};

const post = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  logErr: boolean = true
): Promise<Result<T>> => {
  try {
    const res = await axios.post<T>(url, data, config);
    return { tag: "ok", data: res.data };
  } catch (err) {
    logErr && console.log(err);
    return { tag: "err", err: err };
  }
};

const patch = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  logErr: boolean = true
): Promise<Result<T>> => {
  try {
    const res = await axios.patch<T>(url, data, config);
    return { tag: "ok", data: res.data };
  } catch (err) {
    logErr && console.log(err);
    return { tag: "err", err: err };
  }
};

const put = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  logErr: boolean = true
): Promise<Result<T>> => {
  try {
    const res = await axios.put<T>(url, data, config);
    return { tag: "ok", data: res.data };
  } catch (err) {
    logErr && console.log(err);
    return { tag: "err", err: err };
  }
};

const Http = { get, post, patch, put };

export default Http;
