import * as api from "../lib/Api";
import * as Util from "../lib/Utility";
import {
  FilterableJobProperty,
  defaultJobPropertyFilters,
} from "../lib/FilterableJobProperty";
import { AppDispatch, RootState } from "../ReduxStore";
import {
  ActionCreatorWithPayload,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

export const SERVICE_CENTERS_LOCALSTORAGE_KEY = "OSA.AORS";
export const JOB_FILTER_LOCALSTORAGE_KEY = "OSA.JOB_FILTER";
export const SHOW_CREW_JOBS_LOCALSTORAGE_KEY = "OSA.SHOW_CREW_JOBS";
export const INCLUDE_FOLLOW_UP_JOBS_LOCALSTORAGE_KEY =
  "OSA.INCLUDE_FOLLOW_UP_JOBS";
export const JOB_PROPERTY_FILTER_LOCALSTORAGE_KEY = "OSA.JOB_PROPERTY_FILTER";
export const SELECTED_JOB_PROPERTY_FILTER_LOCALSTORAGE_KEY =
  "OSA.SELECTED_JOB_PROPERTY_FILTER";

export interface jobState {
  jobs: api.JobDetailShort[];
  serviceCenter: Util.ServiceCenterSelection;
  allJobs: api.JobDetailShort[];
  crewJobs: api.JobDetailShort[];
  outageJobs: api.JobDetailShort[];
  treeTrimJobs: api.JobDetailShort[];
  nonOutageJobs: api.JobDetailShort[];
  damageAssessJobs: api.JobDetailShort[];
  streetLightJobs: api.JobDetailShort[];
  jobFilter: Util.JobTypeFilter;
  jobPropertyFilter: string;
  selectedJobPropertyFilter: FilterableJobProperty;
  showCrewJobs: boolean;
  includeFollowUpJobs: boolean;
  loading: boolean;
}

const initialState: jobState = {
  jobs: [],
  serviceCenter: Util.ALL_SERVICE_CENTER, // defaulted to all to match old implementation from local storage reducer
  jobFilter: "All", // defaulted to all for same reason as above
  jobPropertyFilter: "",
  selectedJobPropertyFilter: defaultJobPropertyFilters[0],
  allJobs: [],
  crewJobs: [],
  outageJobs: [],
  treeTrimJobs: [],
  nonOutageJobs: [],
  damageAssessJobs: [],
  streetLightJobs: [],
  showCrewJobs: false,
  includeFollowUpJobs: false,
  loading: false,
};

export const getJobs = createAsyncThunk<
  {
    data: api.JobDetailShort[];
    filter: Util.JobTypeFilter;
  },
  void,
  { state: RootState }
>("jobs/fetchJobs", async (_, thunkAPI) => {
  const {
    jobs: { serviceCenter, jobFilter, includeFollowUpJobs },
  } = thunkAPI.getState();
  const res = await api.getJobDetailByFilter({
    ...Util.buildJobFilter(jobFilter, serviceCenter, includeFollowUpJobs),
  });
  if (res.tag === "ok") {
    if (res.data) {
      return {
        filter: jobFilter,
        data: res.data.filter(
          (x) =>
            !Util.FilteredCrewAssignStatus.includes(
              x.crewAssignmentStatus ?? ""
            ) && !Util.FilteredJobStatus.includes(x.jobStatus ?? "")
        ),
      };
    }
  } else {
    try {
      if (res.err.response.status === 404) {
        return { data: [] };
      } else {
        alert("Error retrieving jobs");
        return res.err.response;
      }
    } catch (err) {
      alert("Error retrieving jobs");
      return err;
    }
  }
});

export const getJobsByCrews = createAsyncThunk<
  api.JobDetailShort[],
  void,
  { state: RootState; dispatch: AppDispatch }
>("jobs/fetchJobsByCrews", async (_, { dispatch, getState }) => {
  const {
    role,
    jobs: { serviceCenter },
  } = getState();
  if (role?.value) {
    const res = await api.getJobDetailByCrewListAndSC(
      role.value.crews,
      serviceCenter
    );
    if (res.tag === "ok") {
      if (Array.isArray(res.data)) {
        const filterErrs = (
          res.data.filter(
            (x: api.JobByCrew | api.JobByCrewErr) =>
              !("errorCode" in x) && "jobId" in x
          ) as api.JobByCrew[]
        ).filter(
          (x) =>
            !Util.FilteredCrewAssignStatus.includes(
              x.crewAssignmentStatus ?? ""
            ) && !Util.FilteredJobStatus.includes(x.jobStatus ?? "")
        );
        return filterErrs.map(api.convertJobByCrewToJobDetailShort);
      } else {
        if (!("errorCode" in res.data)) {
          return [api.convertJobByCrewToJobDetailShort(res.data)].filter(
            (x) =>
              !Util.FilteredCrewAssignStatus.includes(
                x.crewAssignmentStatus ?? ""
              ) && !Util.FilteredJobStatus.includes(x.jobStatus ?? "")
          );
        } else {
          return [];
        }
      }
    }
    return [];
  } else {
    return [];
  }
});

export const setServiceCenter =
  (value: Util.ServiceCenterSelection) => async (dispatch: AppDispatch) => {
    localStorage.setItem(
      SERVICE_CENTERS_LOCALSTORAGE_KEY,
      JSON.stringify(value)
    );
    dispatch(innerSetServiceCenter(value));
  };

export const setJobFilter =
  (value: Util.JobTypeFilter) => async (dispatch: AppDispatch) => {
    localStorage.setItem(JOB_FILTER_LOCALSTORAGE_KEY, JSON.stringify(value));
    dispatch(innerSetJobFilter(value));
  };

export const setJobPropertyFilter =
  (value: string) => async (dispatch: AppDispatch) => {
    localStorage.setItem(
      JOB_PROPERTY_FILTER_LOCALSTORAGE_KEY,
      JSON.stringify(value)
    );
    dispatch(innerSetJobPropertyFilter(value));
  };

export const setSelectedJobPropertyFilter =
  (value: FilterableJobProperty) => async (dispatch: AppDispatch) => {
    localStorage.setItem(
      SELECTED_JOB_PROPERTY_FILTER_LOCALSTORAGE_KEY,
      JSON.stringify(value)
    );
    dispatch(innerSetSelectedJobPropertyFilter(value));
  };

export const setShowCrewJobs =
  (value: boolean) => async (dispatch: AppDispatch) => {
    localStorage.setItem(
      SHOW_CREW_JOBS_LOCALSTORAGE_KEY,
      JSON.stringify(value)
    );
    dispatch(innerSetShowCrewJobs(value));
  };

export const setIncludeFollowUpJobs =
  (value: boolean) => async (dispatch: AppDispatch) => {
    localStorage.setItem(
      INCLUDE_FOLLOW_UP_JOBS_LOCALSTORAGE_KEY,
      JSON.stringify(value)
    );
    dispatch(innerSetIncludeFollowUpJobs(value));
  };

export const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setServiceCenter: (
      state,
      action: PayloadAction<Util.ServiceCenterSelection>
    ) => {
      state.serviceCenter = action.payload;
    },
    setJobFilter: (state, action: PayloadAction<Util.JobTypeFilter>) => {
      state.jobFilter = action.payload;
    },
    setJobPropertyFilter: (state, action: PayloadAction<string>) => {
      state.jobPropertyFilter = action.payload;
    },
    setSelectedJobPropertyFilter: (
      state,
      action: PayloadAction<FilterableJobProperty>
    ) => {
      state.selectedJobPropertyFilter = action.payload;
    },
    setShowCrewJobs: (state, action: PayloadAction<boolean>) => {
      state.showCrewJobs = action.payload;
    },
    setIncludeFollowUpJobs: (state, action: PayloadAction<boolean>) => {
      state.includeFollowUpJobs = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getJobs.pending, (state, _) => {
      state.loading = true;
    });
    builder.addCase(getJobs.rejected, (state, _) => {
      state.loading = false;
    });
    builder.addCase(getJobs.fulfilled, (state, action) => {
      state.loading = false;
      switch (action.payload.filter) {
        case "All":
          state.allJobs = action.payload.data;
          return;

        case "DamageAssess":
          state.damageAssessJobs = action.payload.data;
          return;

        case "StreetLight":
          state.streetLightJobs = action.payload.data;
          return;

        case "NonOutage":
          state.nonOutageJobs = action.payload.data;
          return;

        case "Outage":
          state.outageJobs = action.payload.data;
          return;

        case "TreeTrim":
          state.treeTrimJobs = action.payload.data;
      }
    });
    builder.addCase(getJobsByCrews.pending, (state, _) => {
      state.loading = true;
    });
    builder.addCase(getJobsByCrews.rejected, (state, _) => {
      state.loading = false;
    });
    builder.addCase(getJobsByCrews.fulfilled, (state, action) => {
      state.loading = false;
      state.crewJobs = action.payload;
    });
  },
});

const {
  setServiceCenter: innerSetServiceCenter,
  setJobFilter: innerSetJobFilter,
  setShowCrewJobs: innerSetShowCrewJobs,
  setIncludeFollowUpJobs: innerSetIncludeFollowUpJobs,
  setJobPropertyFilter: innerSetJobPropertyFilter,
  setSelectedJobPropertyFilter: innerSetSelectedJobPropertyFilter,
} = jobSlice.actions;
// Action creators are generated for each case reducer function
export const { setLoading } = jobSlice.actions;

export default jobSlice.reducer;
