import React from "react";
import Popup from "reactjs-popup";
import * as util from "../../lib/Utility";
import * as api from "../../lib/Api";
import { useAppSelector, useAppDispatch } from "../../lib/hooks";
import { setLoading } from "../../features/jobSlice";
import { TextAreaControl } from "../controls/TextAreaControl";
import ValueSelectorModal from "./ValueSelectorModal";
import Row from "../Row";

const CloseJobModal = (props: {
  job: api.JobDetail;
  afterSubmit: Function;
  jobType: string | undefined;
}) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);
  const [comment, setComment] = React.useState("");
  const [selectedMaterialAffected, setSelectedMaterialAffected] =
    React.useState<string[]>([]);
  const [causeCode, setCauseCode] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (props.job.causeCode) {
      setCauseCode([props.job.causeCode]);
    }
  }, [props.job.causeCode]);

  const handleSubmit = async () => {
    dispatch(setLoading(true));

    let jobUpdate: api.JobUpdateReq;

    switch (props.jobType) {
      case "SERVICE DOWN NO LIGHTS":
      case "OUTAGE":
        jobUpdate = {
          msgId: props.job.jobId,
          jobStatus: {
            jobStatus: "Completed",
            comments: `${role?.name} closed job ${props.job.jobDisplayId}. ${comment}`,
          },
          causeCode: {
            causeCode: causeCode[0],
            comments: `${role?.name} updated Cause Code to ${causeCode[0]}`,
          },
          materialAffected: selectedMaterialAffected,
        };
        break;
      case "TREE TRIM":
        jobUpdate = {
          msgId: props.job.jobId,
          jobStatus: {
            jobStatus: "Completed",
            comments: `${role?.name} closed job ${props.job.jobDisplayId}. ${comment}`,
          },
          materialAffected: ["NO MATERIAL AFFECTED"],
        };
        break;
      default:
        jobUpdate = {
          msgId: props.job.jobId,
          jobStatus: {
            jobStatus: "Completed",
            comments: `${role?.name} closed job ${props.job.jobDisplayId}. ${comment}`,
          },
          materialAffected: selectedMaterialAffected,
        };
        break;
    }

    const res = await api.updateJob(props.job.jobId, jobUpdate);

    if (res.tag === "ok") {
      alert("Job closed successfully");
      props.afterSubmit();
    } else {
      alert(
        "Job Close failed, check your internet connection. You can resend the request through the Drafts page"
      );
      dispatch(setLoading(false));
    }
  };

  const isJobNotClosable = () => {
    return (
      !util.closeableStatusCodes.includes(props.job.jobStatus) ||
      props.job.crew.filter(
        (crew) =>
          Object.keys(crew).length !== 0 &&
          crew.crewAssignmentStatus !== "Canceled" &&
          crew.crewAssignmentStatus !== "Completed"
      ).length > 0
    );
  };

  return (
    <>
      <Popup
        closeOnDocumentClick={false}
        modal={true}
        disabled={isJobNotClosable()}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default" }}
        className="responsive-modal"
        trigger={
          <button
            className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white"
            disabled={isJobNotClosable()}
          >
            Close Job
          </button>
        }
      >
        {(closeModal: () => void) => (
          <div className="text-black py-1 flex justify-center flex-col">
            <p className="mb-1 text-center">
              Are you sure you want to close this job?
            </p>

            {props.jobType !== "TREE TRIM" && (
              <div className="border-l border-r border-t border-gray-300 mb-0.5">
                <Row label="Materials Affected" required={true}>
                  <div
                    className="py-0.5 text-center cursor-pointer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ValueSelectorModal
                      componentLabel="Select All Material Affected"
                      multiselect={true}
                      valueList={util.materialAffectedList}
                      selectedValues={selectedMaterialAffected}
                      onChange={setSelectedMaterialAffected}
                    />
                  </div>
                </Row>
                {(props.jobType === "OUTAGE" ||
                  props.jobType === "SERVICE DOWN NO LIGHTS") && (
                  <Row label="Cause Code" required={true}>
                    <div
                      className="py-0.5 text-center cursor-pointer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ValueSelectorModal
                        componentLabel="Select the Cause Code"
                        multiselect={false}
                        valueList={util.causeCodes}
                        selectedValues={causeCode}
                        onChange={setCauseCode}
                      />
                    </div>
                  </Row>
                )}
              </div>
            )}

            <TextAreaControl
              label="Notes"
              value={comment}
              setValue={(value) => setComment(value)}
              placeholder="Enter notes..."
              required={true}
            />

            <div className="w-full inline-flex flex-no-wrap justify-center items-center my-1">
              <button
                className="px-1 py-0.5 bg-gray-200 font-bold mr-1"
                disabled={
                  comment === "" ||
                  (props.jobType !== "TREE TRIM" &&
                    selectedMaterialAffected.length === 0) ||
                  ((props.jobType === "OUTAGE" ||
                    props.jobType === "SERVICE DOWN NO LIGHTS") &&
                    causeCode.length === 0)
                }
                onClick={() => {
                  handleSubmit();
                  closeModal();
                }}
              >
                Yes
              </button>
              <button
                className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                onClick={() => {
                  // if we abort - set list value list to defaults
                  setSelectedMaterialAffected([]);
                  setCauseCode(
                    props.job.causeCode ? [props.job.causeCode] : []
                  );
                  setComment("");
                  closeModal();
                }}
              >
                No
              </button>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default CloseJobModal;
