import * as React from "react";
import { setServiceCenter } from "../features/jobSlice";
import { useAppDispatch, useAppSelector } from "../lib/hooks";
import * as Util from "../lib/Utility";

const Settings: React.FC = (): React.ReactElement => {
  const serviceCenters = useAppSelector((state) => state.jobs.serviceCenter);
  const dispatch = useAppDispatch();

  const handleChecked = (checked: boolean, sc: Util.ServiceCenter) => {
    const otherScs =
      serviceCenters === Util.ALL_SERVICE_CENTER
        ? Util.serviceCenters
        : serviceCenters;
    if (checked) {
      dispatch(setServiceCenter([sc, ...otherScs]));
    } else {
      const otherServiceCenters = otherScs.filter((x) => x.value !== sc.value);
      dispatch(setServiceCenter(otherServiceCenters));
    }
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      dispatch(setServiceCenter(Util.ALL_SERVICE_CENTER));
    } else {
      dispatch(setServiceCenter([]));
    }
  };

  return (
    <>
      <div className="w-1/2 m-auto text-black flex flex-col justify-center">
        <h4 className="py-1 text-center text-2xl">Current Service Centers</h4>
        <ul className="md:ml-6 mt-1">
          <li>
            <input
              className="mr-0.5"
              type="checkbox"
              id="selectAll"
              onChange={(e) => handleSelectAll(e.target.checked)}
              checked={
                serviceCenters === Util.ALL_SERVICE_CENTER ||
                serviceCenters.length === Util.serviceCenters.length
              }
            />
            <label>Select All</label>
          </li>
          <br />
          <hr />
          {Util.serviceCenters.map((sc, i) => {
            return (
              <li key={i}>
                <input
                  className="mr-0.5"
                  checked={determineChecked(serviceCenters, sc.value)}
                  onChange={(e) => handleChecked(e.target.checked, sc)}
                  type="checkbox"
                  value={sc.value}
                />
                {`${sc.label} (${sc.value})`}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Settings;

const determineChecked = (scs: Util.ServiceCenterSelection, val: string) => {
  if (scs === Util.ALL_SERVICE_CENTER) {
    return true;
  } else if (scs.filter((x) => x.value === val).length > 0) {
    return true;
  } else {
    return false;
  }
};
