import React, { ReactNode, Fragment } from "react";
import {
  faCaretDown,
  faCaretUp,
  faMap,
} from "@fortawesome/free-solid-svg-icons";
import {
  withRouter,
  RouteComponentProps,
  useParams,
  useHistory,
  Link,
} from "react-router-dom";
import CauseCodeModal from "../components/modals/CauseCodeModal";
import {
  JobDetail,
  getJobDetailById,
  updateJob,
  getLongLat,
  getCircuitMap,
  getJobHistoryByPremiseId,
  HistoricJob,
  AMIJobStatus,
  //getAMIJobStatus,
  SingleComment,
  restoreJob,
} from "../lib/Api";
import ETRModal from "../components/modals/ETRModal";
import JobSubTypeModal from "../components/modals/JobSubTypeModal";
import TakePhotoModal from "../components/modals/TakePhotoModal";
import UploadPhotoModal from "../components/modals/UploadPhotoModal";
import CloseJobModal from "../components/modals/CloseModal";
import * as Util from "../lib/Utility";
import { useAppSelector, useAppDispatch } from "../lib/hooks";
import { setLoading } from "../features/jobSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import HistoricJobModal from "../components/modals/HistoricJobModal";
import DamageAssessmentFormModal from "../components/modals/DamageAssessmentForm";
import he from "he";
import TreeTrimActivityList from "../components/TreeTrim/TreeTrimActivityList";
import {
  clearTreeTrimConfirmQuestions,
  getTreeTrimActivities,
  getTreeTrimOutageInfo,
} from "../features/treeTrimSlice";
import TreeTrimCancelCloseModal from "../components/modals/TreeTrimCancelCloseModal";
import TreeTrimOutageInfoList from "../components/TreeTrim/TreeTrimOutageInfoList";
import { trackLocationData } from "../lib/api/trackLocationApi";
import DrivingDirectionsModal from "../components/modals/DrivingDirectionsModal";
import CrewAssignmentsTable from "../components/CrewAssignmentsTable";

const Row = (props: {
  label: string;
  value?: string | number | ReactNode;
  children?: any;
}) => {
  return (
    <div
      className="py-0.5 px-1 min-h-3.5 flex justify-between border-b border-gray-300 items-center"
      key={props.label}
    >
      <div className="mr-2">{props.label}:</div>
      <div style={{ wordBreak: "break-word" }} className="text-right">
        {props.children
          ? props.children
          : props.value && props.value !== ""
          ? props.value
          : "N/A"}
      </div>
    </div>
  );
};

type Tab = "Comments" | "Job History" | "Activity" | "Outage Info";

const DetailsPage: React.FC<RouteComponentProps> = (): React.ReactElement => {
  const urlParams = useParams<{ id: string }>();
  const [jobDetail, setJobDetail] = React.useState<JobDetail | null>(null);
  //const [amiData, setAMIData] = React.useState<AMIInfo>(nothingYet());
  const [enteredComment, setEnteredComment] = React.useState("");
  const history = useHistory();
  const [latLong, setLatLong] = React.useState({ lat: "", long: "" });
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);
  const loading = useAppSelector((state) => state.jobs.loading);
  const [currentTab, setCurrentTab] = React.useState<Tab>("Comments");
  const [historicJobs, setHistoricJobs] = React.useState<HistoricJob[]>([]);
  const [jobHistoryErr, setJobHistoryErr] = React.useState(false);
  const [sortKey, setSortKey] = React.useState<SortKey | null>("outageDate");
  const [sortDir, setSortDir] = React.useState<SortDir>("asc");
  const [uniqueAdresses, setUniqueAdresses] = React.useState<String[]>([]);
  const [restoreDisabled, setRestoreDisabled] = React.useState(false);
  const [restoreRequested, setRestoreRequested] = React.useState(false);

  React.useEffect(() => {
    const getJobHistory = async () => {
      if (jobDetail && jobDetail.premiseId && jobDetail.premiseId !== "0") {
        const res = await getJobHistoryByPremiseId(jobDetail.premiseId);
        if (res.tag === "ok") {
          setHistoricJobs(res.data);
        } else {
          if (res.err.response.status !== 404) {
            setJobHistoryErr(true);
          }
        }
      }
    };

    getJobHistory();
  }, [jobDetail]);

  React.useEffect(() => {
    if (!restoreRequested) {
      setRestoreDisabled(
        !Util.RestorableEnergizationStatus.includes(
          jobDetail?.energizationStatus ?? ""
        )
      );
    }
  }, [jobDetail, restoreRequested]);

  const getJobDetail = async () => {
    dispatch(setLoading(true));
    const res = await getJobDetailById(urlParams.id);
    if (res.tag === "ok") {
      setJobDetail(res.data);

      const uniqueAdressesList = [
        ...new Set(
          res.data?.crew
            ?.filter(
              (crew) =>
                Object.keys(crew).length !== 0 &&
                crew.crewAssignmentStatus !== "Canceled" &&
                crew.crewAssignmentStatus !== "Completed"
            )
            .filter(
              (c) => role?.crews.includes(c.crewId) || role?.tag === "employee"
            )
            .map((crew) => crew.destination)
        ),
      ];
      setUniqueAdresses(uniqueAdressesList);

      if (uniqueAdressesList.length === 1) {
        const crewAdress = res.data?.crew
          ?.filter(
            (crew) =>
              Object.keys(crew).length !== 0 &&
              crew.crewAssignmentStatus !== "Canceled" &&
              crew.crewAssignmentStatus !== "Completed"
          )
          .find((c) => c.destination === uniqueAdressesList[0]);
        if (crewAdress?.latitude && crewAdress?.longitude) {
          setLatLong({
            lat: String(crewAdress?.latitude),
            long: String(crewAdress?.longitude),
          });
        } else {
          setLatLong({
            lat: String(res.data.latitude),
            long: String(res.data.longitude),
          });
        }
      } else {
        setLatLong({
          lat: String(res.data.latitude),
          long: String(res.data.longitude),
        });
      }

      dispatch(getTreeTrimActivities(res.data.jobId));
      dispatch(getTreeTrimOutageInfo(res.data.jobId));
    } else {
      setJobDetail(null);
    }
    dispatch(setLoading(false));
  };

  const afterSubmit = () => {
    setTimeout(() => getJobDetail(), 1500);
  };

  React.useEffect(() => {
    getJobDetail();
    dispatch(clearTreeTrimConfirmQuestions());
  }, []);

  // React.useEffect(() => {
  //   const getAMI = async () => {
  //     if (jobDetail) {
  //       const jobStatusAMI = await getAMIJobStatus(jobDetail.jobId);

  //       if (jobStatusAMI.tag === "ok") {
  //         setAMIData({ tag: "ok", res: jobStatusAMI.data });
  //       }
  //     }
  //   };

  //   getAMI();
  // }, [jobDetail]);

  const handleCircuitMap: React.MouseEventHandler<
    HTMLButtonElement
  > = async () => {
    if (jobDetail?.circuit) {
      await getCircuitMapFile(jobDetail.circuit);
    }
  };

  const handleNominalCircuitMap: React.MouseEventHandler<
    HTMLButtonElement
  > = async () => {
    if (jobDetail?.nominalCircuit) {
      await getCircuitMapFile(jobDetail.nominalCircuit);
    }
  };

  const handleRestore: React.MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    e.preventDefault();
    if (jobDetail?.jobId) {
      setRestoreDisabled(true);
      setRestoreRequested(true);
      const res = await restoreJob(jobDetail.jobId);
      if (res.tag !== "ok") {
        setRestoreDisabled(false);
        setRestoreRequested(false);
        alert("Error sending Restre event");
      } else {
        const comment = await updateJob(jobDetail?.jobId, {
          msgId: jobDetail.jobId,
          jobComments: `${role?.name ?? ""} Requested Job Restore`,
        });
        if (comment.tag === "ok") {
          setTimeout(getJobDetail, 1500);
        }
        setTimeout(() => {
          setRestoreDisabled(false);
          setRestoreRequested(false);
          getJobDetail();
        }, 120000);
      }
    }
  };

  const getCircuitMapFile = async (circuit: string) => {
    var translated = Util.oneLinesPDFUrl(circuit ?? "");
    if (translated === "") {
      alert("Error translating Circuit to file name");
    }
    // otherwise call the new APIM
    const res = await getCircuitMap(translated);
    if (res.tag === "ok") {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      if (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform)
      ) {
        a.download = crypto.randomUUID() + ".pdf";
      }
      document.body.append(a);
      a.click();
      a.remove();
    } else {
      alert("Error getting Circuit Map");
    }
  };

  const setSort = (key: SortKey) => {
    if (key === sortKey) {
      if (sortDir === "desc") {
        setSortDir("asc");
      } else if (sortDir === "asc") {
        setSortDir("desc");
        setSortKey(null);
      }
    } else {
      setSortKey(key);
    }
  };

  const renderCurrentTab = (tab: Tab) => {
    switch (tab) {
      case "Activity":
        return <TreeTrimActivityList job={jobDetail!} />;
      case "Comments":
        return (
          <JobCommentsList
            jobDetail={jobDetail}
            getJobDetail={getJobDetail}
            role={role}
          />
        );
      case "Job History":
        return (
          <JobHistoryList
            setSort={setSort}
            sortDir={sortDir}
            sortKey={sortKey}
            historicJobs={historicJobs}
            jobHistoryError={jobHistoryErr}
          />
        );
      case "Outage Info":
        return <TreeTrimOutageInfoList job={jobDetail!} />;
      default:
        break;
    }
  };

  const isTreeTrimJob = (): boolean => {
    return Util.TreeTrimJobs.includes(jobDetail?.jobType!);
  };

  const encodeJobInfo = (): string => {
    if (jobDetail) {
      const info = {
        jobDisplayId: jobDetail.jobDisplayId,
        address: jobDetail.address,
      };
      return btoa(JSON.stringify(info));
    }
    return "";
  };

  // finished loading and the job or job status is not valid show the not available message for entire job
  if (
    !loading &&
    (jobDetail === null ||
      Util.cancelOrCompletedJobStatuses.includes(jobDetail?.jobStatus))
  ) {
    return (
      <>
        <div className="text-black p-1 pt-0.25 text-base md:w-3/4 mx-auto">
          <div className="inline-flex flex-wrap mb-0.5 mt-2.5 justify-center">
            Job is in a closed status or not available.
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="text-black p-1 pt-0.25 text-base md:w-3/4 mx-auto">
      <div className="inline-flex flex-wrap mb-0.5 mt-0.5">
        {role?.tag === "employee" && (
          <Link
            className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white"
            to={`/map?x=${latLong.long}&y=${latLong.lat}&j=${encodeJobInfo()}`}
          >
            View on Map
          </Link>
        )}
        {(uniqueAdresses.length === 1 && !latLong?.lat && !latLong?.long) ||
        (uniqueAdresses.length === 0 && !latLong?.lat && !latLong?.long) ? (
          <a
            className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white disabled"
            onClick={(event) => event.preventDefault()}
          >
            Driving Directions
            <FontAwesomeIcon className="ml-1" icon={faExternalLinkAlt} />
          </a>
        ) : uniqueAdresses.length === 1 || uniqueAdresses.length === 0 ? (
          <a
            className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white"
            href={Util.getMapLink(latLong.lat, latLong.long)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Driving Directions
            <FontAwesomeIcon className="ml-1" icon={faExternalLinkAlt} />
          </a>
        ) : (
          <DrivingDirectionsModal
            crews={jobDetail?.crew.filter(
              (crew) =>
                Object.keys(crew).length !== 0 &&
                crew.crewAssignmentStatus !== "Canceled" &&
                crew.crewAssignmentStatus !== "Completed"
            )}
          />
        )}
        {role?.tag === "employee" &&
          Util.OutageJobs.includes(jobDetail?.jobType ?? "") && (
            <a
              className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white"
              href={Util.getSOSUrlForJob(jobDetail?.jobDisplayId ?? "")}
              target="_blank"
              rel="noopener noreferrer"
            >
              Calls for Outage
              <FontAwesomeIcon className="ml-1" icon={faExternalLinkAlt} />
            </a>
          )}
        {role?.tag === "employee" && (
          <a
            className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white"
            href={Util.getSOSUrlForJobCalls(jobDetail?.jobDisplayId ?? "")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Calls
            <FontAwesomeIcon className="ml-1" icon={faExternalLinkAlt} />
          </a>
        )}
        <TakePhotoModal
          jobId={jobDetail?.jobId ?? ""}
          afterSubmit={afterSubmit}
        />
        <UploadPhotoModal
          jobId={jobDetail?.jobId ?? ""}
          afterSubmit={afterSubmit}
        />

        {(Util.closeJobTypes.includes(jobDetail?.jobType ?? "") ||
          (Util.ClosableOutageJobs.includes(jobDetail?.jobType ?? "") &&
            jobDetail?.crew.some(
              (c) =>
                role?.crews.includes(c.crewId) &&
                !Util.crewTypesNotAllowedToCloseOutages.includes(
                  c.crewType.toUpperCase()
                )
            ))) && (
          <CloseJobModal
            job={jobDetail!}
            afterSubmit={() => history.push("/list")}
            jobType={jobDetail?.jobType}
          />
        )}

        {Util.RestorableOutageJobs.includes(jobDetail?.jobType ?? "") &&
          jobDetail?.crew.some(
            (c) =>
              role?.crews.includes(c.crewId) &&
              !Util.crewTypesNotAllowedToCloseOutages.includes(
                c.crewType.toUpperCase()
              )
          ) && (
            <button
              className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white"
              onClick={handleRestore}
              disabled={restoreDisabled}
            >
              Restore
            </button>
          )}

        {jobDetail && (
          <DamageAssessmentFormModal
            job={jobDetail}
            afterSubmit={() => getJobDetail()}
          />
        )}
      </div>

      <div className="border-l border-r border-t border-gray-300 mb-0.5">
        <Row label="Location" value={jobDetail?.address} />
        {uniqueAdresses.length === 1 &&
          uniqueAdresses[0] !== jobDetail?.address && (
            <Row label="Destination address" value={uniqueAdresses[0]} />
          )}
        <Row label="Meter Id" value={jobDetail?.meterId} />
        <Row label="Job Type" value={jobDetail?.jobType} />
        <Row label="Job Status" value={jobDetail?.jobStatus} />
        {jobDetail?.jobType === "TREE TRIM" ? (
          <>
            <Row label="Job Sub Type">
              <div
                className={`px-1 py-0.5 text-center cursor-pointer 
              ${
                Util.TTJobSubTypeOptions.find(
                  (x) => x.value === jobDetail.jobSubType
                )
                  ? "text-green-600"
                  : "text-red-600"
              }${" "}
              ${jobDetail?.jobSubType !== "??" ? "" : "border-2 border-red-700"}
            `}
                onClick={(e) => e.stopPropagation()}
              >
                {jobDetail ? (
                  <JobSubTypeModal
                    showTextArea={false}
                    jobSubTypeOptions={Util.TTJobSubTypeOptions}
                    job={jobDetail}
                    afterSubmit={afterSubmit}
                  />
                ) : null}
              </div>
            </Row>
            <Row label="Call Src." value={jobDetail?.callSource} />
            <Row label="Name" value={jobDetail?.name} />
            <Row label="Phone">
              <a
                href={`tel:${jobDetail.phoneNumber}`}
                className="text-dte-500 underline"
              >
                {jobDetail.phoneNumber}
              </a>
            </Row>
          </>
        ) : Util.CallCodesForEditSubType.includes(jobDetail?.callCode ?? "") ? (
          <Row label="Job Sub Type">
            <div
              className={`px-1 py-0.5 text-center cursor-pointer 
              ${
                Util.defaultSybtypeOptions.find(
                  (x) => x.value === jobDetail!.jobSubType
                )
                  ? "text-green-600"
                  : "text-red-600"
              }${" "}
              ${jobDetail?.jobSubType !== "??" ? "" : "border-2 border-red-700"}
            `}
              onClick={(e) => e.stopPropagation()}
            >
              {jobDetail ? (
                <JobSubTypeModal
                  job={jobDetail}
                  afterSubmit={afterSubmit}
                  showTextArea={true}
                  jobSubTypeOptions={Util.defaultSybtypeOptions}
                />
              ) : null}
            </div>
          </Row>
        ) : (
          <Row label="Job Sub Type" value={jobDetail?.jobSubType} />
        )}
        {Util.RestorableOutageJobs.includes(jobDetail?.jobType ?? "") &&
          jobDetail?.crew.some(
            (c) =>
              role?.crews.includes(c.crewId) &&
              !Util.crewTypesNotAllowedToCloseOutages.includes(
                c.crewType.toUpperCase()
              )
          ) && (
            <Row
              label="Job Energization Status"
              value={jobDetail?.energizationStatus}
            />
          )}
        <Row label="Circuit">
          {jobDetail?.circuit !== "" ? (
            <div
              className="py-0.5 text-center cursor-pointer"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                className={`inline-flex flex-no-wrap whitespace-no-wrap items-center justify-center w-full cursor-pointer text-blue-600`}
                onClick={handleCircuitMap}
                disabled={!jobDetail?.circuit}
              >
                {jobDetail?.circuit}
                <FontAwesomeIcon className="ml-1" icon={faMap} />
              </button>
            </div>
          ) : (
            "No Circuit"
          )}
        </Row>
        {jobDetail?.nominalCircuit && (
          <Row label="Nominal Circuit">
            {jobDetail?.nominalCircuit !== "" ? (
              <div
                className="py-0.5 text-center cursor-pointer"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className={`inline-flex flex-no-wrap whitespace-no-wrap items-center justify-center w-full cursor-pointer text-blue-600`}
                  onClick={handleNominalCircuitMap}
                  disabled={!jobDetail?.nominalCircuit}
                >
                  {jobDetail?.nominalCircuit}
                  <FontAwesomeIcon className="ml-1" icon={faMap} />
                </button>
              </div>
            ) : (
              "No Circuit"
            )}
          </Row>
        )}
        {Util.OutageJobs.includes(jobDetail?.jobType ?? "") && (
          <>
            <Row label="Cust Out" value={jobDetail?.customerOut} />
            <Row label="Cust Calls" value={jobDetail?.numOfCalls} />
            <Row label="PRNs" value={jobDetail?.prn_flag} />
            <Row label="Device type" value={jobDetail?.deviceType} />
            <Row label="Extent" value={jobDetail?.extent} />
          </>
        )}
        {jobDetail?.jobType !== "TREE TRIM" && (
          <Row label="ETR">
            <div
              className="py-0.5 text-center cursor-pointer"
              onClick={(e) => e.stopPropagation()}
            >
              {jobDetail ? (
                <ETRModal job={jobDetail} afterSubmit={afterSubmit} />
              ) : null}
            </div>
          </Row>
        )}
        <Row label="Cause Code">
          <div
            className="py-0.5 text-center cursor-pointer"
            onClick={(e) => e.stopPropagation()}
          >
            {jobDetail ? (
              <CauseCodeModal job={jobDetail} afterSubmit={afterSubmit} />
            ) : null}
          </div>
        </Row>
      </div>
      {jobDetail?.crew &&
        jobDetail.crew.filter(
          (crew) =>
            Object.keys(crew).length !== 0 &&
            crew.crewAssignmentStatus !== "Canceled" &&
            crew.crewAssignmentStatus !== "Completed"
        ).length > 0 && (
          <CrewAssignmentsTable
            jobDetail={jobDetail}
            afterSubmit={afterSubmit}
          />
        )}
      <div className="w-full mb-1.5 mt-2.5 inline-flex">
        <button
          className={`${
            currentTab === "Comments" ? "border-b-2 border-blue-500" : ""
          } pb-0.5 mr-3 text-xl`}
          onClick={() => setCurrentTab("Comments")}
        >
          Comments
        </button>
        <button
          className={`${
            currentTab === "Job History" ? "border-b-2 border-blue-500" : ""
          } pb-0.5 mr-3 text-xl`}
          onClick={() => setCurrentTab("Job History")}
        >
          Job History
        </button>
        <button
          className={`${
            currentTab === "Activity" ? "border-b-2 border-blue-500" : ""
          } pb-0.5 mr-3 text-xl`}
          onClick={() => setCurrentTab("Activity")}
        >
          TT Activity
        </button>
        <button
          className={`${
            currentTab === "Outage Info" ? "border-b-2 border-blue-500" : ""
          } pb-0.5 mr-3 text-xl`}
          onClick={() => setCurrentTab("Outage Info")}
        >
          TT Outage Info
        </button>
      </div>

      {renderCurrentTab(currentTab)}
    </div>
  );
};

type SortKey = keyof HistoricJob;

type SortDir = "asc" | "desc";

const TableHeader = (props: {
  value: SortKey;
  label: String;
  onClick: (key: SortKey) => void;
  sortKey: SortKey | null;
  sortDir: SortDir;
}) => (
  <th
    scope="col"
    className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
    onClick={() => props.onClick(props.value)}
  >
    {props.label}

    <FontAwesomeIcon
      className={`ml-0.5 ${
        props.value === props.sortKey ? "opacity-100" : "opacity-0"
      } `}
      icon={props.sortDir === "asc" ? faCaretUp : faCaretDown}
    />
  </th>
);

const sortByHeader = (
  key: SortKey | null,
  dir: SortDir,
  data: HistoricJob[]
) => {
  if (key !== null) {
    switch (dir) {
      case "asc":
        const ascList = [...data].sort((a, b) => (a[key]! < b[key]! ? 1 : -1));
        return ascList;
      case "desc":
        const descList = [...data].sort((a, b) => (a[key]! > b[key]! ? 1 : -1));
        return descList;
    }
  } else {
    return data;
  }
};

type AMINotFound = { tag: "notFound" };
//const nothingYet = (): AMINotFound => ({ tag: "notFound" });
type AMIOk = { tag: "ok"; res: AMIJobStatus };
type AMIError = { tag: "err" };
type AMIInfo = AMIOk | AMINotFound | AMIError;

const AMIRows = (props: { amiInfo: AMIInfo }): JSX.Element => {
  switch (props.amiInfo.tag) {
    case "ok":
      return (
        <>
          <Row
            label="Customers Affected"
            value={
              <div className={"text-red-600"}>{props.amiInfo.res.affected}</div>
            }
          />
          <Row
            label="Customers Restored"
            value={
              <div className={"text-green-600"}>
                {props.amiInfo.res.restored}
              </div>
            }
          />
          <Row
            label="Customers Reported"
            value={
              <div className={"text-red-600"}>{props.amiInfo.res.powerOut}</div>
            }
          />
        </>
      );
    case "notFound":
      return (
        <>
          <Row label="Customers Affected" value={"N/A"} />
          <Row label="Customers Restored" value={"N/A"} />
          <Row label="Customers Reported" value={"N/A"} />
        </>
      );
    case "err":
      return (
        <>
          <Row label="Customers Affected" value={"N/A"} />
          <Row label="Customers Restored" value={"N/A"} />
          <Row label="Customers Reported" value={"N/A"} />
        </>
      );
  }
};
const Comment: React.FC<{ comment: SingleComment }> = ({ comment }) => {
  const renderComment = () => {
    const decoded = he.decode(comment.comment);
    let result;
    // highlight all links
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urls = decoded.match(urlRegex);

    if (urls) {
      let elements: Object[] = [];
      decoded.split(urlRegex).forEach((el) => {
        if (el.startsWith("http")) {
          elements.push(
            React.createElement(
              "a",
              {
                target: "_blank",
                rel: "noopener noreferrer",
                className: "underline text-dte-600",
                href: el,
                key: el,
              },
              el
            )
          );
        } else {
          elements.push(
            el
              .replaceAll(/(\r?\n)?(<|&lt;)br\s*\/*(>|&gt;)(\r?\n)?/g, "\n")
              .replaceAll(/\r?\n\r?\n/g, "\n")
              .split(/\r?\n/)
              .filter((e) => e)
              .map((e, i) => (
                <Fragment key={i}>
                  {e}
                  <br />
                </Fragment>
              ))
          );
        }
      });

      result = React.createElement("p", null, elements);
    } else {
      result = (
        <p>
          {decoded
            .replaceAll(/(\r?\n)?(<|&lt;)br\s*\/*(>|&gt;)(\r?\n)?/g, "\n")
            .split(/\r?\n/)
            .map((s, i) => (
              <Fragment key={i}>
                {s}
                <br />
              </Fragment>
            ))}
        </p>
      );
    }

    return result;
  };

  return (
    <div className="inline-flex flex-no-wrap">
      <div className="flex flex-no-wrap">
        <p className="whitespace-no-wrap w-9.25">
          {comment.timestamp &&
            new Date(comment.timestamp).toLocaleString(...Util.dateFormat)}
        </p>
        <p className="w-1">:</p>
      </div>

      {renderComment()}
    </div>
  );
};

const JobCommentsList = (props: {
  jobDetail: JobDetail | null;
  role: Util.Role | null;
  getJobDetail: () => void;
}) => {
  const [enteredComment, setEnteredComment] = React.useState("");
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);

  const saveComment = async () => {
    dispatch(setLoading(true));
    if (props.jobDetail) {
      await trackLocationData("updateJob", props.jobDetail.jobId, role!);

      const res = await updateJob(props.jobDetail?.jobId, {
        msgId: props.jobDetail.jobId,
        jobComments: `${props.role?.name ?? ""} : ${enteredComment}`,
      });
      if (res.tag === "ok") {
        setEnteredComment("");
        setTimeout(props.getJobDetail, 1500);
      } else {
        alert(
          "Job update failed, check your internet connection. You can resend the request through the Drafts page"
        );
      }
    }
    dispatch(setLoading(false));
  };
  return (
    <div className="flex flex-col justify-center mb-1">
      <textarea
        placeholder="Add New Comment"
        value={enteredComment}
        className="border border-gray-300 mb-1 h-2.75 focus:h-6 w-full mx-auto p-0.5"
        onChange={(e: any) => setEnteredComment(e.target.value)}
      ></textarea>
      {enteredComment.length > 0 && (
        <button
          className="bg-dte-500 py-0.25 px-1 text-white w-7 mx-auto mb-1"
          onClick={saveComment}
        >
          Save Comment
        </button>
      )}
      {props.jobDetail &&
        ("comments" in props.jobDetail.comments ? (
          Array.isArray(props.jobDetail.comments.comments) ? (
            props.jobDetail.comments.comments
              .sort(Util.sortCommentsByDate)
              .map((x, i) => <Comment comment={x} key={i} />)
          ) : (
            <Comment comment={props.jobDetail.comments.comments} />
          )
        ) : "comment" in props.jobDetail?.comments &&
          props.jobDetail?.comments?.comment !== "" &&
          props.jobDetail?.comments?.timestamp !== "" ? (
          <Comment comment={props.jobDetail.comments} />
        ) : null)}
    </div>
  );
};
const JobHistoryList = (props: {
  setSort: any;
  sortKey: SortKey | null;
  sortDir: SortDir;
  historicJobs: HistoricJob[];
  jobHistoryError: boolean;
}) => {
  if (props.jobHistoryError) {
    return (
      <div className="w-full flex flex-wrap max-h-screen h-full overflow-x-auto">
        Error getting job history
      </div>
    );
  }

  if (props.historicJobs.length === 0) {
    return (
      <div className="w-full flex flex-wrap max-h-screen h-full overflow-x-auto">
        No job history found
      </div>
    );
  }

  return (
    <div className="w-full flex flex-wrap max-h-screen h-full overflow-x-auto">
      <table className="table-auto bg-white w-full text-black border mb-2">
        <thead className="bg-gray-200">
          <tr className="cursor-pointer">
            <TableHeader
              value="jobDisplayId"
              label="Job"
              onClick={props.setSort}
              sortKey={props.sortKey}
              sortDir={props.sortDir}
            />
            <TableHeader
              value="jobType"
              label="Type"
              onClick={props.setSort}
              sortKey={props.sortKey}
              sortDir={props.sortDir}
            />
            <TableHeader
              value="causeCode"
              label="Cause Code"
              onClick={props.setSort}
              sortKey={props.sortKey}
              sortDir={props.sortDir}
            />
            <TableHeader
              value="circuit"
              label="Circuit"
              onClick={props.setSort}
              sortKey={props.sortKey}
              sortDir={props.sortDir}
            />
            <TableHeader
              value="outageDate"
              label="Outage Date"
              onClick={props.setSort}
              sortKey={props.sortKey}
              sortDir={props.sortDir}
            />
            <TableHeader
              value="RestorationDate"
              label="Restoration Date"
              onClick={props.setSort}
              sortKey={props.sortKey}
              sortDir={props.sortDir}
            />
          </tr>
        </thead>
        <tbody>
          {props.historicJobs.map((job, i) => (
            <HistoricJobModal
              jobs={sortByHeader(
                props.sortKey,
                props.sortDir,
                props.historicJobs
              )}
              index={i}
              key={i}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default withRouter(DetailsPage);
