import React from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { getOutageCounts } from "../features/outageCountsSlice";
import { useAppSelector, useAppDispatch } from "../lib/hooks";
import { setServiceCenter, setShowCrewJobs } from "../features/jobSlice";
import * as Util from "../lib/Utility";

const regions = [
  { value: "All", label: "All" },
  { value: "D", label: "Southeast" },
  { value: "W", label: "Southwest" },
  { value: "T", label: "Northeast" },
  { value: "N", label: "Northwest" },
];

const OutagePage = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [region, setRegion] = React.useState({ value: "All", label: "All" });
  const online = useAppSelector((state) => state.online.value);
  const outageCounts = useAppSelector((state) => state.outageCounts.value);

  React.useEffect(() => {
    if (online) {
      dispatch(getOutageCounts());
    }
  }, [online]);

  return (
    <>
      <div className="w-full p-1 text-2xl text-black max-h-full overflow-y-auto">
        <div className="w-full md:w-25 mx-auto text-base flex justify-center">
          <Select
            blurInputOnSelect={true}
            styles={{
              control: () => ({
                border: "none",
                display: "inline-flex",
                flexWrap: "nowrap",
                width: "100%",
              }),
            }}
            className="mb-1 w-1/2 border-2 border-dte-500"
            options={regions}
            value={region}
            onChange={(e: any) => setRegion({ label: e.label, value: e.value })}
          />
        </div>
        <div className="inline-flex flex-wrap max-w-screen justify-center max-h-full">
          {outageCounts
            .filter((x) => {
              if (region.value === Util.ALL_SERVICE_CENTER) {
                return true;
              } else {
                return region.value === x.region;
              }
            })
            .map((sc, i) => (
              <div
                key={i}
                className="px-1 pt-1 mb-1 w-10 md:w-20 cursor-pointer"
                onClick={() => {
                  dispatch(setShowCrewJobs(false));
                  dispatch(setServiceCenter([sc]));
                  history.push("/list");
                }}
              >
                <div className="card shadow-lg border-gray-400 h-full flex flex-col">
                  <h4 className="mx-auto text-center">
                    {sc.value}: {sc.count}
                  </h4>
                  <h3 className="mx-auto text-center">{sc.description}</h3>
                  {sc.value !== "TNA" && (
                    <h3 className="mx-auto text-center">Region: {sc.region}</h3>
                  )}
                  <div className="w-full inline-flex justify-end cursor-pointer mt-auto">
                    <FontAwesomeIcon
                      className="ml-auto mr-1 mb-0.5"
                      icon={faList}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default OutagePage;
