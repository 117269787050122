import clsx from "clsx";
import React from "react";
import Select from "react-select";
import { DropdownOption } from "../../common/models/DropdownOption";

const Label: React.FC<{
  label?: string;
  labelHTML?: boolean;
  error?: boolean;
  required?: boolean;
}> = ({ label, labelHTML, error, required }) => {
  return (
    <div className="flex flex-no-wrap mb-0.5">
      {labelHTML ? (
        <label
          className={clsx("font-medium mr-0.25", error && "error-control")}
          dangerouslySetInnerHTML={{ __html: label || "" }}
        ></label>
      ) : (
        <label
          className={clsx("font-medium mr-0.25", error && "error-control")}
        >
          {label}
        </label>
      )}

      {required && <p className="text-red-600">*</p>}
    </div>
  );
};

export const DropdownControl: React.FC<{
  label?: string;
  className?: string;
  dropdownClassName?: string;
  value: DropdownOption | null;
  options?: DropdownOption[];
  required?: boolean;
  disabled?: boolean;
  setValue: (value: string) => void;
  placeholder?: string;
  error?: boolean;
}> = ({
  label,
  className,
  dropdownClassName,
  value,
  options,
  required,
  disabled,
  setValue,
  placeholder,
  error,
}) => {
  const handleDropdownChange = (e: any) => {
    setValue(e.value);
  };

  return (
    <div className={clsx("flex flex-col w-full", className)}>
      {label ? (
        <Label label={label} required={required} error={error} />
      ) : (
        <></>
      )}
      <div className="flex flex-wrap">
        <Select
          placeholder={placeholder || "Select..."}
          className={clsx("w-full", dropdownClassName)}
          options={options}
          styles={{
            control: (base) => ({
              ...base,
              color: error ? "red" : base.color,
              borderColor: error ? "red" : base.borderColor,
            }),
          }}
          onChange={handleDropdownChange}
          value={value}
          isDisabled={disabled}
          maxMenuHeight={200}
        />
      </div>
    </div>
  );
};
