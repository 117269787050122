import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FailedRequest = {
  url: string;
  data: any;
  id: string;
};

export interface DraftsState {
  drafts: FailedRequest[];
}

const initialState: DraftsState = {
  drafts: [],
};

export const draftsSlice = createSlice({
  name: "drafts",
  initialState,
  reducers: {
    setDrafts: (state, action: PayloadAction<FailedRequest[]>) => {
      state.drafts = action.payload;
    },
    addDraft: (state, action: PayloadAction<FailedRequest>) => {
      state.drafts.push(action.payload);
    },
    removeDraft: (state, action: PayloadAction<string>) => {
      state.drafts = state.drafts.filter((x) => x.id !== action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDrafts, addDraft, removeDraft } = draftsSlice.actions;

export default draftsSlice.reducer;
