import React from "react";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

const ValueSelectorModal = (props: {
  valueList: {
    value: string;
    label: string;
  }[];
  selectedValues: string[];
  multiselect: boolean;
  componentLabel: string;
  onChange: Function;
}) => {
  const [selectedValues, setSelectedValues] = React.useState<string[]>(
    props.selectedValues
  );

  const label =
    props.selectedValues.length > 0 ? props.selectedValues.join(", ") : "??";

  const toggleSelectedValue = (value: string) => {
    if (props.multiselect) {
      if (selectedValues.includes(value)) {
        setSelectedValues(selectedValues.filter((x) => x !== value));
      } else {
        setSelectedValues([value, ...selectedValues]);
      }
    } else {
      setSelectedValues([value]);
    }
  };

  return (
    <>
      <Popup
        closeOnDocumentClick={true}
        modal={true}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default" }}
        className={clsx("responsive-modal", props.multiselect ? "wide" : "")}
        trigger={
          <div
            className={`inline-flex flex-no-wrap whitespace-no-wrap items-center justify-center w-full cursor-pointer ${
              label === "??" ? "text-red-600" : "text-green-600"
            }`}
          >
            {label}
            <FontAwesomeIcon className="ml-1" icon={faEdit} />
          </div>
        }
      >
        {(close: () => void) => (
          <div className="text-black py-1">
            <div className="flex flex-no-wrap mb-0.5">
              <label className="font-medium mr-0.25">
                {props.componentLabel}:
              </label>
              <p className="text-red-600">*</p>
            </div>

            <div
              className={clsx(
                "grid px-0.5",
                props.multiselect
                  ? "gap-0.25 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4"
                  : "gap-1 grid-cols-2"
              )}
            >
              {props.valueList.map((x) => (
                <button
                  className={`${
                    selectedValues.includes(x.value)
                      ? "bg-white border border-dte-500 text-dte-500"
                      : "border border-dte-500 bg-dte-500 text-white"
                  } mx-0.5 my-0.5 py-0.5 px-0.75`}
                  onClick={() => toggleSelectedValue(x.value)}
                  key={x.value}
                >
                  {x.label}
                </button>
              ))}
            </div>

            <div className="w-full inline-flex flex-no-wrap justify-center my-1">
              <button
                className="px-1 py-0.5 bg-gray-200 font-bold mr-1"
                disabled={selectedValues.length < 1}
                onClick={() => {
                  props.onChange(selectedValues);
                  close();
                }}
              >
                Submit
              </button>
              <button
                className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                onClick={() => {
                  setSelectedValues(props.selectedValues);
                  close();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default ValueSelectorModal;
