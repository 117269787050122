import React from "react";
import { JobDetail, JobDetailShort } from "../../lib/Api";
import { useAppSelector } from "../../lib/hooks";
import TreeTrimActivitiesModal from "./TreeTrimActivitiesModal";

const AddActivity: React.FC<{
  job: JobDetail | JobDetailShort;
}> = ({ job }) => {
  return (
    <div className="w-full m-auto">
      <div className="flex justify-center mt-1">
        <TreeTrimActivitiesModal job={job} />
      </div>
    </div>
  );
};

const TreeTrimActivityList: React.FC<{
  job: JobDetail | JobDetailShort;
}> = ({ job }) => {
  const treeTrimActivities = useAppSelector(
    (state) => state.treeTrim.activities
  );

  //empty array of activities
  if (treeTrimActivities?.length === 0) {
    return (
      <>
        <div className="py-0.5">No activities found</div>
        <AddActivity job={job} />
      </>
    );
  }

  return (
    <>
      <div className="w-full flex flex-wrap max-h-screen h-full overflow-x-auto">
        <table className="table-auto bg-white w-full text-black border mb-2">
          <thead className="bg-gray-200">
            <tr className="cursor-pointer">
              <th
                scope="col"
                className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
              >
                Activity Type
              </th>
              <th
                scope="col"
                className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
              >
                Code
              </th>
              <th
                scope="col"
                className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
              >
                Unit
              </th>
              <th
                scope="col"
                className="w-1/2 px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
              >
                Notes
              </th>
            </tr>
          </thead>
          <tbody>
            {treeTrimActivities?.map((tt) => (
              <tr className="hover:bg-gray-200 cursor-pointer" key={tt.id}>
                <td className="border text-center py-0.5 px-0.25 md:px-1">
                  {tt.activityType}
                </td>
                <td className="border px-0.25 md:px-1 text-center py-0.5">
                  {tt.activityCode}
                </td>
                <td className="border px-0.25 md:px-1 text-center py-0.5">
                  {tt.quantity}
                </td>
                <td className="border px-0.25 md:px-1 text-center py-0.5">
                  {tt.notes}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <AddActivity job={job} />
    </>
  );
};

export default TreeTrimActivityList;
