import React from "react";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  JobDetailShort,
  updateJob,
  Etr,
  JobUpdateReq,
  JobDetail,
} from "../../lib/Api";
import { useAppSelector, useAppDispatch } from "../../lib/hooks";
import { setLoading } from "../../features/jobSlice";
import * as Util from "../../lib/Utility";

const ETRModal = (props: {
  job: JobDetail | JobDetailShort;
  afterSubmit: Function;
}) => {
  const [selectedDate, setSelectedDate] = React.useState<string>(
    Util.isValidDate(props.job.etr)
      ? new Date(props.job.etr).toLocaleString(...Util.dateFormat)
      : new Date().toLocaleString()
  );
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);

  React.useEffect(() => {
    setSelectedDate(
      Util.isValidDate(props.job.etr)
        ? new Date(props.job.etr).toLocaleString(...Util.dateFormat)
        : new Date().toLocaleString()
    );
  }, [props.job.etr]);

  const handleSubmit = async () => {
    const req: JobUpdateReq = {
      msgId: props.job.jobId,
      etr: {
        etr: Util.isValidDate(selectedDate)
          ? new Date(selectedDate).toISOString()
          : null,
        comments: `${role?.name ?? ""} updated ETR to ${new Date(
          selectedDate
        ).toLocaleString(...Util.dateFormat)}`,
      },
    };
    dispatch(setLoading(true));
    const res = await updateJob(props.job.jobId, req);
    if (res.tag === "ok") {
      await props.afterSubmit();
    } else {
      console.log(res.err);
      alert(
        "Error updating job, check your internet connection. You can resend the request through the Drafts page"
      );
      dispatch(setLoading(false));
    }
  };

  const timeDiff =
    new Date(
      Util.isValidDate(selectedDate) ? selectedDate : Date.now()
    ).getTime() - Date.now();

  const inPast = timeDiff < 0;

  const highlight =
    inPast || !props.job.etr
      ? "text-red-600"
      : timeDiff < Util.MS_HOURS
      ? "text-yellow-600"
      : "text-green-600";

  return (
    <>
      {props.job.jobStatus !== Util.JOB_STATUS_NEW ? (
        <Popup
          closeOnDocumentClick={true}
          modal={true}
          overlayStyle={{ cursor: "default" }}
          contentStyle={{ cursor: "default" }}
          className="responsive-modal"
          onClose={() =>
            setSelectedDate(
              Util.isValidDate(props.job.etr)
                ? new Date(props.job.etr).toLocaleString(...Util.dateFormat)
                : new Date().toLocaleString()
            )
          }
          onOpen={() =>
            setSelectedDate(
              Util.isValidDate(props.job.etr)
                ? new Date(props.job.etr).toLocaleString(...Util.dateFormat)
                : new Date().toLocaleString()
            )
          }
          trigger={
            <div
              className={`inline-flex flex-no-wrap whitespace-no-wrap items-center justify-center w-full cursor-pointer ${highlight}`}
            >
              {Util.isValidDate(props.job.etr)
                ? new Date(props.job.etr).toLocaleString(...Util.dateFormat)
                : "No ETR"}
              <FontAwesomeIcon className="ml-1" icon={faEdit} />
            </div>
          }
        >
          {(close: () => void) => (
            <div className="text-black py-1">
              <p className="mb-1">Set the ETR</p>
              <DatePicker
                selected={
                  isNaN(Date.parse(selectedDate))
                    ? new Date()
                    : new Date(selectedDate)
                }
                showTimeSelect={true}
                timeFormat="hh:mm a"
                timeIntervals={15}
                onChange={(d: Date) =>
                  setSelectedDate(d.toLocaleString(...Util.dateFormat))
                }
                inline
              />
              <p className="text-lg font-bold py-0.5">
                {Util.isValidDate(selectedDate)
                  ? new Date(selectedDate).toLocaleString(...Util.dateFormat)
                  : "No ETR"}
              </p>
              <div className="w-full inline-flex flex-no-wrap justify-center items-center">
                <button
                  className="px-1 py-0.5 bg-gray-200 font-bold mr-1"
                  onClick={() => {
                    handleSubmit();
                    close();
                  }}
                >
                  Submit
                </button>
                <button
                  className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                  onClick={() => {
                    setSelectedDate(
                      Util.isValidDate(props.job.etr)
                        ? new Date(props.job.etr).toLocaleString(
                            ...Util.dateFormat
                          )
                        : new Date().toLocaleString()
                    );
                    close();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </Popup>
      ) : (
        <div
          className={`inline-flex flex-no-wrap whitespace-no-wrap items-center justify-center w-full ${highlight}`}
        >
          {Util.isValidDate(props.job.etr)
            ? new Date(props.job.etr).toLocaleString(...Util.dateFormat)
            : "No ETR"}
        </div>
      )}
    </>
  );
};

export default ETRModal;
