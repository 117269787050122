import { useEffect, useState } from "react";
import { WorkLogItemModel, WorkOrderModel } from "../../lib/models/WorkOrder";
import * as Util from "../../lib/Utility";
import WorkOrderFormModal from "./WorkOrderFormModal";
import WorkOrderLogModal from "./WorkOrderLogModal";

const WorkOrderItem: React.FC<{
  data: WorkOrderModel;
  afterUpdate: () => void;
}> = ({ data, afterUpdate }) => {
  const [latestUpdate, setLatestUpdate] = useState<WorkLogItemModel | null>(
    null
  );

  useEffect(() => {
    if (!data.worklog) {
      return;
    }
    const sortedWorkLogItems = data.worklog.sort((a, b) =>
      new Date(a.modifydate) > new Date(b.modifydate) ? -1 : 1
    );
    setLatestUpdate(sortedWorkLogItems[0]);
  }, [data]);

  return (
    <div className="flex flex-wrap w-full mb-1.5 p-1 bg-gray-100 text-sm">
      <div className="w-5/6 p-1">
        <div className="flex mb-1">
          <div className="w-1/4">
            <label className="text-xs">Work Order</label>
            <br />
            <span className="sm:text-sm md:text-md lg:text-lg xl:text-xl font-bold">
              {data.wonum}
            </span>
          </div>
          <div className="w-1/4">
            <label className="text-xs">Crew Id</label>
            <br />
            <span className="sm:text-sm md:text-md lg:text-lg xl:text-xl font-bold">
              {data.dte_assignment}
            </span>
          </div>
          <div className="w-1/4">
            <label className="text-xs">Status</label>
            <br />
            <span className="sm:text-sm md:text-md lg:text-lg xl:text-xl font-bold">
              {data.status}
            </span>
          </div>
          <div className="w-1/4">
            <label className="text-xs">Type</label>
            <br />
            <span className="sm:text-sm md:text-md lg:text-lg xl:text-xl font-bold">
              {data.dte_tasktype}
            </span>
          </div>
        </div>
        <div className="flex mb-1">
          <div className="w-1/6 pr-0.25">
            <label className="text-xs">Address</label>
            <br />
            <span className="text-xs font-bold">{data.dte_addcombo}</span>
          </div>
          <div className="w-1/6">
            <label className="text-xs">InService Event</label>
            <br />
            <span className="text-xs font-bold">
              {data.dte_externalid || "N/A"}
            </span>
          </div>
          <div className="w-1/6">
            <label className="text-xs">Documentum Link</label>
            <br />
            <a
              className="text-xs underline text-dte-600 font-bold"
              rel="noreferrer"
              href={data.dte_workorder[0].dte_dctmurl}
              target="_blank"
            >
              Open
            </a>
          </div>
          <div className="w-1/6">
            <label className="text-xs">Target Start Date</label>
            <br />
            <span className="text-xs font-bold">
              {data.targstartdate
                ? new Date(data.targstartdate).toLocaleString(
                    ...Util.dateFormat
                  )
                : "N/A"}
            </span>
          </div>
          <div className="w-1/6">
            <label className="text-xs">Target End Date</label>
            <br />
            <span className="text-xs font-bold">
              {data.targcompdate
                ? new Date(data.targcompdate).toLocaleString(...Util.dateFormat)
                : "N/A"}
            </span>
          </div>
          <div className="w-1/6">
            <label className="text-xs">Lead Id/Owner Group</label>
            <br />
            <span className="text-xs font-bold">{data.lead || "N/A"}</span>
          </div>
        </div>
        <div>{data.description}</div>
        <hr className="mt-0.5 mb-0.5"></hr>
        {latestUpdate ? (
          <div className="mb-1">
            <label className="text-xs text-gray-600 font-bold">
              Latest Update -{" "}
              {new Date(latestUpdate?.modifydate).toLocaleString(
                ...Util.dateFormat
              )}
            </label>
            <br />
            <span>{latestUpdate?.description}</span>
          </div>
        ) : (
          <></>
        )}
        <div>
          <WorkOrderFormModal data={data} afterUpdate={afterUpdate} />
          <WorkOrderLogModal data={data} />
        </div>
      </div>
      <div className="flex flex-1 flex-col w-1/6 h-8 py-1">
        <div>
          <label className="text-xs">Child Work Order</label>
          <br />
          <div>
            <span className="text-lg font-bold">
              {data.dte_workorder[0].parent_wonum}
            </span>
            <br />
            <span>{data.dte_workorder[0].parent_desc}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkOrderItem;
