import { useState } from "react";
import { DropdownOption } from "../../common/models/DropdownOption";
import {
  getActivityTypeOptions,
  getActivityCodeOptions,
} from "../../lib/api/treeTrimApi";
import {
  TreeTrimActivity,
  TreeTrimActivityKey,
} from "../../lib/models/TreeTrimActivity";
import { DropdownControl } from "../controls/DropdownControl";
import { NumericControl } from "../controls/NumericControl";
import { TextAreaControl } from "../controls/TextAreaControl";
import FormRow from "../FormRow";

const TreeTrimActivityForm: React.FC<{
  treeTrimActivity: TreeTrimActivity;
  onUpdate: (value: TreeTrimActivity) => void;
  errors: TreeTrimActivityKey[];
}> = ({ treeTrimActivity, onUpdate, errors }) => {
  const [selectedActivityType, setSelectedActivityType] = useState<string>(
    treeTrimActivity.activityType
  );

  const getSelectedActivityTypeOption = (
    activityType: string
  ): DropdownOption | null => {
    return (
      getActivityTypeOptions().find(
        (option) => option.value === activityType
      ) || null
    );
  };

  const getSelectedActivityCodeOption = (
    activityType: string,
    activityCode: string
  ): DropdownOption | null => {
    const activityCodeOptions = getActivityCodeOptions(activityType);
    return (
      activityCodeOptions.find((option) => option.value === activityCode) ||
      null
    );
  };

  const fieldHasError = (field: string) => {
    return errors?.find((e) => e === field) !== undefined;
  };

  return (
    <>
      <FormRow>
        <DropdownControl
          className="w-full"
          label="Activity Type"
          required={true}
          error={fieldHasError("activityType")}
          value={getSelectedActivityTypeOption(treeTrimActivity?.activityType)}
          options={getActivityTypeOptions()}
          setValue={(value) => {
            onUpdate({
              ...treeTrimActivity,
              activityType: value,
              activityCode: "",
            });
            setSelectedActivityType(value);
          }}
        />
      </FormRow>
      <FormRow>
        <DropdownControl
          label="Activity Code"
          required={true}
          error={fieldHasError("activityCode")}
          value={getSelectedActivityCodeOption(
            selectedActivityType ?? treeTrimActivity?.activityType,
            treeTrimActivity.activityCode
          )}
          options={getActivityCodeOptions(selectedActivityType)}
          setValue={(value) => {
            onUpdate({ ...treeTrimActivity, activityCode: value });
          }}
        />
      </FormRow>
      <FormRow>
        <NumericControl
          label="Unit"
          placeholder="#"
          required={true}
          error={fieldHasError("quantity")}
          value={treeTrimActivity.quantity}
          setValue={(value) => {
            onUpdate({ ...treeTrimActivity, quantity: value });
          }}
        />
      </FormRow>
      <FormRow>
        <TextAreaControl
          label="Notes"
          value={treeTrimActivity.notes}
          error={fieldHasError("notes")}
          setValue={(value) => {
            onUpdate({ ...treeTrimActivity, notes: value });
          }}
        />
      </FormRow>
    </>
  );
};

export default TreeTrimActivityForm;
