import React from "react";
import clsx from "clsx";

export type Size = "normal" | "large";

const Label: React.FC<{
  label?: string;
  instructions?: string;
  error?: boolean;
  required?: boolean;
}> = ({ label, instructions, error, required }) => {
  return label ? (
    <>
      <div className="flex flex-no-wrap mb-0.5">
        <label
          className={clsx("font-medium mr-0.25", error && "error-control")}
        >
          {label}
        </label>
        {required && <p className="text-red-600">*</p>}
        {instructions && <p className="text-gray-600">{instructions}</p>}
      </div>
    </>
  ) : (
    <></>
  );
};

export const TextAreaControl: React.FC<{
  label?: string;
  instructions?: string;
  value: string;
  required?: boolean;
  disabled?: boolean;
  setValue: (value: string) => void;
  size?: Size;
  error?: boolean;
  placeholder?: string;
  maxLength?: number;
}> = ({
  label,
  instructions,
  value,
  setValue,
  required,
  disabled,
  size,
  error,
  placeholder,
  maxLength,
}) => {
  const getHeight = () => {
    if (size === "large") {
      return "h-12";
    } else {
      return "h-5.5";
    }
  };

  return (
    <div className={clsx("flex flex-col w-full", disabled && "disabled")}>
      <Label
        label={label}
        instructions={instructions}
        error={error}
        required={required}
      />
      <div className="flex flex-wrap w-full">
        <textarea
          maxLength={maxLength}
          disabled={disabled}
          value={value}
          className={clsx(
            "p-0.5 bg-white w-full border border-dte-500",
            getHeight()
          )}
          placeholder={placeholder || "Enter Notes..."}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </div>
  );
};
