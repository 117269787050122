import { AxiosRequestConfig } from "axios";
import { DropdownOption } from "../../common/models/DropdownOption";
import http from "../Http";
import { TreeTrimActivity } from "../models/TreeTrimActivity";
import { TreeTrimOutageInfo } from "../models/TreeTrimOutageInfo";

const headers = {
  headers: {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUB_KEY,
  },
};

const config: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_API_URL}/taskmanagement`,
  ...headers,
};

export const getTreeTrimActivitiesForJob = async (jobId: string) => {
  const params = {
    jobId,
  };
  const res = await http.get<TreeTrimActivity[]>("/getTreeTrimActivities", {
    ...config,
    params,
  });
  return res;
};

export const getTreeTrimOutageInfoForJob = async (jobId: string) => {
  const params = {
    jobId,
  };
  const res = await http.get<TreeTrimOutageInfo[]>("/getTreeTrimOutageInfo", {
    ...config,
    params,
  });
  return res;
};

export const saveTreeTrimActivity = async (
  treeTrimActivity: TreeTrimActivity
) => {
  const res = await http.post<TreeTrimActivity>(
    "/saveTreeTrimActivity",
    treeTrimActivity,
    config
  );
  return res;
};

export const removeTreeTrimActivity = async (id: string) => {
  const res = await http.post<{ id: string }>(
    "/removeTreeTrimActivity",
    { id },
    config
  );
  return res;
};

export const saveTreeTrimActivities = async (
  treeTrimActivities: TreeTrimActivity[],
  removeTreeTrimIds: string[]
) => {
  const saveResults = await Promise.all(
    treeTrimActivities.map((a) => saveTreeTrimActivity(a))
  );

  const removeResults = await Promise.all(
    removeTreeTrimIds.map((id) => removeTreeTrimActivity(id))
  );

  return [...saveResults, ...removeResults];
};

export const saveOutageInfo = async (treeTrimActivity: TreeTrimOutageInfo) => {
  const res = await http.post<TreeTrimOutageInfo>(
    "/saveTreeTrimOutageInfo",
    treeTrimActivity,
    config
  );
  return res;
};

export const removeOutageInfo = async (id: string) => {
  const res = await http.post<{ id: string }>(
    "/removeTreeTrimOutageInfo",
    { id },
    config
  );
  return res;
};

export const saveTreeTrimOutageInfo = async (
  treeTrimActivities: TreeTrimOutageInfo[],
  removeTreeTrimIds: string[]
) => {
  const saveResults = await Promise.all(
    treeTrimActivities.map((a) => saveOutageInfo(a))
  );

  const removeResults = await Promise.all(
    removeTreeTrimIds.map((id) => removeOutageInfo(id))
  );

  return [...saveResults, ...removeResults];
};

export const getActivityTypeOptions = (): DropdownOption[] => [
  { label: "Tree Removal", value: "Tree Removal" },
  { label: "Tree Trimming", value: "Tree Trimming" },
  { label: "Brush Work", value: "Brush Work" },
  { label: "C Cut", value: "C Cut" },
  { label: "No Work", value: "No Work" },
];

export const getActivityCodeOptions = (
  activityType?: string
): DropdownOption[] => {
  if (activityType === "Tree Removal") {
    return [
      {
        label: 'B1R - Tree removal 4-10" dbh and treat all stumps, Roadside',
        value: "B1R",
      },
      {
        label: 'B1B - Tree removal 4-10" dbh and treat all stumps, Backyard',
        value: "B1B",
      },
      {
        label:
          'B2R - Tree removal 10.1-18" dbh and treat all strumps, Roadside',
        value: "B2R",
      },
      {
        label:
          'B2B - Tree removal 10.1-18" dbh and treat all strumps, Backyard',
        value: "B2B",
      },
      {
        label:
          'B3R - Tree removal 18.1-24" dbh and treat all strumps, Roadside',
        value: "B3R",
      },
      {
        label:
          'B3B - Tree removal 18.1-24" dbh and treat all strumps, Backyard',
        value: "B3B",
      },
      {
        label:
          'B4R - Tree removal 24.1-32" dbh and treat all strumps, Roadside',
        value: "B4R",
      },
      {
        label:
          'B4B - Tree removal 24.1-32" dbh and treat all strumps, Backyard',
        value: "B4B",
      },
      {
        label: 'B5R - Tree removal 32.1+" dbh and treat all strumps, Roadside',
        value: "B5R",
      },
      {
        label: 'B5B - Tree removal 32.1+" dbh and treat all strumps, Backyard',
        value: "B5B",
      },
    ];
  } else if (activityType === "Tree Trimming") {
    return [
      { label: "A1R - Side/top trim, no overhang, Roadside", value: "A1R" },
      { label: "A1B - Side/top trim, no overhand, Backyard", value: "A1B" },
      {
        label:
          "A2R - Side/top trim, remove all overhang 10' above conductors, Roadside",
        value: "A2R",
      },
      {
        label:
          "A2B - Side/top trim, remove all overhang 10' above conductors, Backyard",
        value: "A2B",
      },
      {
        label: "A4R - Side/top trim, removal all overhang and shelf, Roadside",
        value: "A4R",
      },
      {
        label: "A4B - Side/top trim, removal all overhang and shelf, Backyard",
        value: "A4B",
      },
      {
        label:
          "A6R - Secondary/Service to Business trim, >5' of clearance, Roadside (no primaries)",
        value: "A6R",
      },
      {
        label:
          "A6B - Secondary/Service to Business trim, >5' of clearance, Backyard (no primaries)",
        value: "A6B",
      },
    ];
  } else if (activityType === "Brush Work") {
    return [
      { label: "F1R - Brush trimming, Roadside", value: "F1R" },
      { label: "F1B - Brush trimming, Backyard", value: "F1B" },
      {
        label: "FR - Brush removal and treat all stumps, Roadside",
        value: "FR",
      },
      {
        label: "FB - Brush removal and treat all stumps, Backyard",
        value: "FB",
      },
      {
        label: "M1R - Brush Mowing, includes debris management, Roadside",
        value: "M1R",
      },
      {
        label: "M1B - Brush Mowing, includes debris management, Backyard",
        value: "M1B",
      },
    ];
  } else if (activityType === "C Cut") {
    return [
      {
        label: "C2R - Top priority tree's 10-18\" dbh, Roadside",
        value: "C2R",
      },
      {
        label: "C2B - Top priority tree's 10-18\" dbh, Backyard",
        value: "C2B",
      },
      {
        label: "C3R - Top priority tree's 18.1-24\" dbh, Roadside",
        value: "C3R",
      },
      {
        label: "C3B - Top priority tree's 18.1-24\" dbh, Backyard",
        value: "C3B",
      },
      {
        label: "C4R - Top priority tree's 24.1-32\" dbh, Roadside",
        value: "C4R",
      },
      {
        label: "C4B - Top priority tree's 24.1-32\" dbh, Backyard",
        value: "C4B",
      },
      {
        label: "C5R - Top priority tree's 32.1+\" dbh, Roadside",
        value: "C5R",
      },
      {
        label: "C5B - Top priority tree's 32.1+\" dbh, Backyard",
        value: "C5B",
      },
    ];
  } else if (activityType === "No Work") {
    return [{ label: "NW - No work", value: "NW" }];
  } else {
    return [];
  }
};

export const getTreeLocationOptions = (): DropdownOption[] => {
  return [
    { label: "In ROW", value: "In ROW" },
    { label: "Out of ROW", value: "Out of ROW" },
  ];
};

export const getTreeSpeciesOptions = (): DropdownOption[] => {
  return [
    { label: "Ornamental", value: "Ornamental" },
    { label: "Ailanthus", value: "Ailanthus" },
    { label: "Apple", value: "Apple" },
    { label: "Arborvitae", value: "Arborvitae" },
    { label: "Ash", value: "Ash" },
    { label: "Austrian Pine", value: "Austrian Pine" },
    { label: "Beech", value: "Beech" },
    { label: "Bigtooth Aspen", value: "Bigtooth Aspen" },
    { label: "Birch", value: "Birch" },
    { label: "Black Locust", value: "Black Locust" },
    { label: "Black Oak", value: "Black Oak" },
    { label: "Black Walnut", value: "Black Walnut" },
    { label: "Blue Spruce", value: "Blue Spruce" },
    { label: "Box-elder", value: "Box-elder" },
    { label: "Buckthorn", value: "Buckthorn" },
    { label: "Bur Oak", value: "Bur Oak" },
    { label: "Cedar", value: "Cedar" },
    { label: "Cherry", value: "Cherry" },
    { label: "Chestnut", value: "Chestnut" },
    { label: "Cottonwood", value: "Cottonwood" },
    { label: "Crab Apple", value: "Crab Apple" },
    { label: "Dogwood", value: "Dogwood" },
    { label: "Eastern Red Cedar", value: "Eastern Red Cedar" },
    { label: "Elm", value: "Elm" },
    { label: "Hackberry", value: "Hackberry" },
    { label: "Hawthorn", value: "Hawthorn" },
    { label: "Hickory", value: "Hickory" },
    { label: "HoneyLocust", value: "HoneyLocust" },
    { label: "Linden", value: "Linden" },
    { label: "Magnolia", value: "Magnolia" },
    { label: "Maple", value: "Maple" },
    { label: "Mulberry", value: "Mulberry" },
    { label: "Northern Catalpa", value: "Northern Catalpa" },
    { label: "Norway Maple", value: "Norway Maple" },
    { label: "Oak", value: "Oak" },
    { label: "Ohio Buckeye", value: "Ohio Buckeye" },
    { label: "Osage Orange", value: "Osage Orange" },
    { label: "Paper Birch", value: "Paper Birch" },
    { label: "Pear", value: "Pear" },
    { label: "Pin Oak", value: "Pin Oak" },
    { label: "Pine", value: "Pine" },
    { label: "Poplar", value: "Poplar" },
    { label: "Quaking Aspen", value: "Quaking Aspe" },
    { label: "Red Maple", value: "Red Maple" },
    { label: "Red Oak", value: "Red Oak" },
    { label: "Red Pine", value: "Red Pine" },
    { label: "Sassafras", value: "Sassafras" },
    { label: "Silver Maple", value: "Silver Maple" },
    { label: "Spruce", value: "Spruce" },
    { label: "Sugar Maple", value: "Sugar Maple" },
    { label: "Sweetgum", value: "Sweetgum" },
    { label: "Sycamore", value: "Sycamore" },
    { label: "Tamarack", value: "Tamarack" },
    { label: "White Oak", value: "White Oak" },
    { label: "White Pine", value: "White Pine" },
    { label: "Willow", value: "Willow" },
    { label: "Yellow Poplar", value: "Yellow Poplar" },
    { label: "Brush", value: "Brush" },
    { label: "Hardwood-Other", value: "Hardwood-Other" },
    { label: "Softwood-Other", value: "Softwood-Other" },
    { label: "Vines", value: "Vines" },
  ];
};

export const getLineTypeOptions = (): DropdownOption[] => {
  return [
    { label: "Sub-Transmission", value: "Sub-Transmission" },
    { label: "Primary", value: "Primary" },
    { label: "Secondary", value: "Secondary" },
    { label: "Service", value: "Service" },
  ];
};

export const getScenarioOptions = (): DropdownOption[] => {
  return [
    { label: "Tree on Equipment", value: "Tree on Equipment" },
    {
      label: "Section of Tree on Equipment",
      value: "Section of Tree on Equipment",
    },
    { label: "Arcing/Sparking", value: "Arcing/Sparking" },
    { label: "Wire-Down", value: "Wire-Down" },
    { label: "Vines on Equipment", value: "Vines on Equipment" },
    {
      label: "Tree Contact with Line due to Wind",
      value: "Tree Contact with Line due to Wind",
    },
    { label: "Broken Limb", value: "Broken Limb" },
    {
      label: "Wire Damage due to Tree Abrasion",
      value: "Wire Damage due to Tree Abrasion",
    },
  ];
};
