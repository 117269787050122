export type TreeTrimOutageInfoKey = keyof TreeTrimOutageInfo;

export interface TreeTrimOutageInfo {
  id: string;
  jobId: string;
  jobType: string;
  jobSubType: string;
  circuit: string | null;
  jobCreateDate: string;
  jobClosureDate?: string;
  treeLocation: string;
  treeSpecies: string;
  lineType: string;
  scenario: string;
  maintenanceSpecMet?: boolean;
  maintenancePreventOutage?: boolean;
  notes: string;
  createUser: string;
  createDate: string;
}

export const validateOutageInfo = (
  tt: TreeTrimOutageInfo
): TreeTrimOutageInfoKey[] => {
  const errors: TreeTrimOutageInfoKey[] = [];

  if (!tt.treeLocation) {
    errors.push("treeLocation");
  }

  if (!tt.treeSpecies) {
    errors.push("treeSpecies");
  }

  if (!tt.lineType) {
    errors.push("lineType");
  }

  if (!tt.scenario) {
    errors.push("scenario");
  }

  if (tt.maintenanceSpecMet === null) {
    errors.push("maintenanceSpecMet");
  }

  if (tt.maintenanceSpecMet && tt.maintenancePreventOutage === null) {
    errors.push("maintenancePreventOutage");
  }

  return errors;
};

export const initTreeTrimOutageInfo = (): TreeTrimOutageInfo => {
  return {
    id: "",
    jobId: "",
    jobType: "",
    jobSubType: "",
    circuit: "",
    jobCreateDate: "",
    jobClosureDate: "",
    treeLocation: "",
    treeSpecies: "",
    lineType: "",
    scenario: "",
    maintenanceSpecMet: undefined,
    maintenancePreventOutage: undefined,
    notes: "",
    createUser: "",
    createDate: "",
  };
};
