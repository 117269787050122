import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { WorkLogItemModel } from "../../lib/models/WorkOrder";
import * as Util from "../../lib/Utility";

const WorkOrderLogItem: React.FC<{ data: WorkLogItemModel }> = ({ data }) => {
  const [isExpand, setExpand] = useState<boolean>(false);
  return (
    <>
      <div className="flex text-xs">
        <div className="w-5/6">
          <label>
            {new Date(data.modifydate).toLocaleString(...Util.dateFormat)} - Log
            type: {data.logtype} {`(${data.logtype_description})`}
          </label>
          <p className="font-bold">{data.description}</p>
          {isExpand ? <p>{data.description_longdescription}</p> : <></>}
        </div>
        <div className="w-1/6 text-center">
          {isExpand ? (
            <FontAwesomeIcon
              icon={faAngleUp}
              className="text-gray-500 align-baseline text-thin text-xl"
              onClick={() => setExpand(!isExpand)}
            />
          ) : (
            <FontAwesomeIcon
              icon={faAngleDown}
              className="text-gray-500 align-baseline text-thin text-xl"
              onClick={() => setExpand(!isExpand)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default WorkOrderLogItem;
