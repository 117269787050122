import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { setLoading } from "../../features/jobSlice";
import { assetManagementConfig } from "../../lib/Api";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import http from "../../lib/Http";
import {
  getWorkOrderStatuses,
  WorkOrderModel,
} from "../../lib/models/WorkOrder";
import {
  MAXIMIO_MAX_LENGTH_LONG_DESC,
  MAXIMO_MAX_LENGTH_DESC,
  MAXIMO_USER_ID,
} from "../../lib/Utility";
import { DropdownControl } from "../controls/DropdownControl";
import { TextAreaControl } from "../controls/TextAreaControl";
import FormRow from "../FormRow";
import Row from "../Row";

const WorkOrderFormModal: React.FC<{
  data: WorkOrderModel;
  afterUpdate: () => void;
}> = ({ data, afterUpdate }) => {
  const loading = useAppSelector((state) => state.jobs.loading);
  const role = useAppSelector((state) => state.role.value);
  const dispatch = useAppDispatch();
  const [isNotesRequired, setIsNotesRequired] = useState<boolean>(false);
  const [status, setStatus] = useState<string>(data.status);
  const [summary, setSummary] = useState<string>("");
  const [fullDescription, setFullDescription] = useState<string>("");

  const [error, setError] = useState<string>("");

  const statusesRequiringNotes = ["FDONHOLD", "FDCOMP"];

  const validate = () => {
    const notesRequired = statusesRequiringNotes.includes(status);

    if (
      notesRequired &&
      (fullDescription.trimEnd() === "" || summary.trimEnd() === "")
    ) {
      setError(`Status ${status} requires a summary and full description.`);
      return false;
    } else {
      if (summary.trimEnd() === "") {
        setError(`Summary is required field.`);
        return false;
      }
    }

    return true;
  };

  const updateWorkOrder = async (close: () => void) => {
    setError("");
    const isFormValid = validate();

    if (!isFormValid) {
      return;
    }

    const userPrefix = `Updated by ${role?.id};`;
    const summaryWithPrefix = userPrefix + summary;

    let worklog = undefined;
    if (fullDescription.trimEnd() === "") {
      worklog = [
        {
          description: summaryWithPrefix,
          logtype: "FDREMARK",
          createdate: new Date().toISOString(),
        },
      ];
    } else {
      worklog = [
        {
          description: summaryWithPrefix,
          description_longdescription: fullDescription,
          logtype: "FDREMARK",
          createdate: new Date().toISOString(),
        },
      ];
    }

    let actfinish = undefined;

    if (status === "FDCOMP") {
      actfinish = new Date().toISOString();
    }

    const response = await http.put(
      `/WorkOrderTask/${data.wonum}`,
      {
        wonum: data.wonum,
        siteid: data.siteid,
        status,
        actfinish: actfinish,
        dte_workorder: [
          {
            dte_userid: MAXIMO_USER_ID,
            dte_sysname: "OSA",
          },
        ],
        worklog,
      },
      assetManagementConfig
    );

    if (response.tag === "err") {
      let message = "";
      if (response.err.response.data) {
        message = JSON.stringify(response.err.response.data);
      }
      alert(`Work order was not updated. ${message}`);
    } else {
      close();
      clearState();
      afterUpdate();
    }
  };

  const clearState = () => {
    setFullDescription("");
    setStatus("");
    setSummary("");
  };

  useEffect(() => {
    setIsNotesRequired(statusesRequiringNotes.includes(status));
  }, [status]);

  return (
    <>
      <Popup
        onOpen={() => {
          clearState();
          setStatus(data.status);
        }}
        closeOnDocumentClick={true}
        modal={true}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default" }}
        className="responsive-modal"
        trigger={
          <button className="mr-0.5 mb-0.25 border border-dte-500 bg-dte-500 py-0.25 px-1 text-white">
            Update Work Item
          </button>
        }
      >
        {(close: () => void) => (
          <div
            className={clsx(
              "justify-center text-black mx-2 my-1 overflow-auto",
              loading && "wait-cursor"
            )}
          >
            <div className="flex justify-end">
              <span className="cursor-pointer">
                <FontAwesomeIcon icon={faTimes} onClick={close} />
              </span>
            </div>
            <p className="mb-1 text-center text-xl">Update Work Item</p>
            <div className="border border-gray mb-2">
              <Row label="Work Order" value={data.wonum} />
              <Row label="Crew Id" value={data.dte_assignment} />
            </div>
            <div className="mb-2">
              {error && <div className="text-red-500">{error}</div>}
              <FormRow>
                <DropdownControl
                  className="w-full"
                  label="Status"
                  required={true}
                  value={{ label: status, value: status }}
                  options={getWorkOrderStatuses(data.status)}
                  setValue={(value) => {
                    setStatus(value);
                  }}
                />
              </FormRow>
              <FormRow>
                <TextAreaControl
                  label="Summary"
                  instructions="(Max 80 Characters)"
                  maxLength={MAXIMO_MAX_LENGTH_DESC}
                  required={true}
                  value={summary}
                  setValue={(value) => {
                    setSummary(value);
                  }}
                />
              </FormRow>
              <FormRow>
                <TextAreaControl
                  label="Full Description"
                  instructions="(Max 32,000 Characters)"
                  maxLength={MAXIMIO_MAX_LENGTH_LONG_DESC}
                  required={isNotesRequired}
                  size="large"
                  value={fullDescription}
                  setValue={(value) => {
                    setFullDescription(value);
                  }}
                />
              </FormRow>
            </div>
            <div className="flex justify-center">
              <button
                disabled={loading}
                className="mr-0.5 mb-0.25 border border-dte-500 bg-dte-500 py-0.25 px-1 text-white 
                                          disabled:bg-gray-500 disabled:border-gray-200 disabled:shadow-none"
                onClick={async () => {
                  dispatch(setLoading(true));
                  await updateWorkOrder(close);
                  dispatch(setLoading(false));
                }}
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default WorkOrderFormModal;
