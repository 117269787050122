import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faRedo } from "@fortawesome/free-solid-svg-icons";

import * as api from "../lib/Api";
import { useAppDispatch, useAppSelector } from "../lib/hooks";
import { FailedRequest, removeDraft } from "../features/draftsSlice";
import { saveDAForm, submitDAForm } from "../lib/api/damageAssessmentApi";
import {
  removeTreeTrimActivity,
  saveTreeTrimActivity,
} from "../lib/api/treeTrimApi";

const Drafts = () => {
  const drafts = useAppSelector((state) => state.drafts);
  return (
    <div className="text-black flex flex-col justify-center items-center mx-1 overflow-y-auto">
      <h4 className="text-2xl">Drafts waiting to be sent</h4>
      <p>
        Updates may be saved as drafts instead due to network connectivity
        issues
      </p>
      <div className="flex flex-col justify-center items-center w-full md:w-30 my-1">
        {drafts.drafts.map((x, i) => (
          <Draft draft={x} id={x.id} key={i} />
        ))}
      </div>
    </div>
  );
};

export default Drafts;

const dateFormat: any = ["en-US", { dateStyle: "short", timeStyle: "short" }];

const parseUpdate = (update: any): string => {
  if ("causeCode" in update) {
    return `Cause Code: ${update?.causeCode?.causeCode ?? "??"}`;
  } else if ("jobSubType" in update) {
    return `Job SubType: ${update?.jobSubType?.jobSubType ?? "??"}`;
  } else if ("etr" in update) {
    return `ETR: ${
      update?.etr?.etr
        ? new Date(update?.etr?.etr).toLocaleString(...dateFormat)
        : "??"
    }`;
  } else if ("jobComments" in update) {
    return `Comment: ${update?.jobComments ?? "??"}`;
  } else if ("photo" in update) {
    return `Photo: ${update?.photo ?? "??"}`;
  } else {
    return "Unknown update";
  }
};

const Draft = (props: { draft: FailedRequest; id: string }) => {
  const dispatch = useAppDispatch();

  const requestType = props.draft.url.startsWith("/Job/Update/")
    ? "Job Update"
    : props.draft.url.includes("CrewAssignment")
    ? "Crew Assignment Status Update"
    : props.draft.url.startsWith("/Crew")
    ? "Crew Status Update"
    : props.draft.url.includes("/saveDamageAssessment")
    ? "Damage Assessment Save"
    : props.draft.url.includes("/submitDamageAssessment")
    ? "Damage Assessment Submit"
    : props.draft.url.includes("/saveTreeTrimActivity")
    ? "Tree Trim Activity"
    : props.draft.url.includes("/removeTreeTrimActivity")
    ? "Remove Tree Trim Activity"
    : "Unknown Update";

  const id =
    requestType === "Crew Assignment Status Update"
      ? `Crew: ${props.draft.url.split("/")[2] ?? "??"}`
      : requestType === "Crew Status Update"
      ? `Crew: ${props.draft.url.split("/")[2] ?? "??"}`
      : requestType === "Job Update"
      ? `Job: ${props.draft.data.msgId ?? "??"}`
      : requestType === "Damage Assessment Save"
      ? `Job: ${props.draft.data?.jobId ?? "??"}`
      : requestType === "Damage Assessment Submit"
      ? `Job: ${props.draft.data?.jobId ?? "??"}`
      : requestType === "Tree Trim Activity"
      ? `Job: ${props.draft.data?.jobId ?? "??"}`
      : requestType === "Remove Tree Trim Activity"
      ? `Job: ${props.draft.data.id ?? "??"}`
      : "Unknown resource";

  const update =
    requestType === "Crew Assignment Status Update"
      ? `Status: ${props.draft.data.crewAssignmentStatus ?? "??"}`
      : requestType === "Crew Status Update"
      ? `Action: ${props.draft.data.action ?? "??"}`
      : requestType === "Job Update"
      ? `${parseUpdate(props.draft.data)}`
      : requestType === "Damage Assessment Save"
      ? "Damage Assessment Save"
      : requestType === "Damage Assessment Submit"
      ? "Damage Assessment Submit"
      : requestType === "Tree Trim Activity"
      ? "Tree Trim Activity"
      : requestType === "Remove Tree Trim Activity"
      ? "Remove Tree Trim Activity"
      : "Unknown resource";

  const retry = async () => {
    switch (requestType) {
      case "Crew Assignment Status Update":
        const crewA = props.draft.url.split("/")[2] ?? "";
        const resA = await api.updateCrewAssignmentStatus(
          crewA,
          props.draft.data
        );
        dispatch(removeDraft(props.id));
        if (resA.tag === "err") {
          alert(
            "Error retrying request, make sure you have network connectivity"
          );
        }
        break;
      case "Crew Status Update":
        const crewS = props.draft.url.split("/")[2] ?? "";
        const resS = await api.updateCrewAssignmentStatus(
          crewS,
          props.draft.data
        );
        dispatch(removeDraft(props.id));
        if (resS.tag === "err") {
          alert(
            "Error retrying request, make sure you have network connectivity"
          );
        }
        break;
      case "Job Update":
        const job = props.draft.data.msgId;
        const resJ = await api.updateJob(job, props.draft.data);
        dispatch(removeDraft(props.id));
        if (resJ.tag === "err") {
          alert(
            "Error retrying request, make sure you have network connectivity"
          );
        }
        break;
      case "Damage Assessment Save":
        const resDASave = await saveDAForm(props.draft.data);
        dispatch(removeDraft(props.id));
        if (resDASave.tag === "err") {
          alert(
            "Error retrying request, make sure you have network connectivity"
          );
        }
        break;
      case "Damage Assessment Submit":
        const resDASubmit = await submitDAForm(props.draft.data);
        dispatch(removeDraft(props.id));
        if (resDASubmit.tag === "err") {
          alert(
            "Error retrying request, make sure you have network connectivity"
          );
        }
        break;
      case "Tree Trim Activity":
        const treeTrimResponse = await saveTreeTrimActivity(props.draft.data);
        dispatch(removeDraft(props.id));
        if (treeTrimResponse.tag === "err") {
          alert(
            "Error retrying request, make sure you have network connectivity"
          );
        }
        break;
      case "Remove Tree Trim Activity":
        const removeTreeTrimResponse = await removeTreeTrimActivity(
          props.draft.data
        );
        dispatch(removeDraft(props.id));
        if (removeTreeTrimResponse.tag === "err") {
          alert(
            "Error retrying request, make sure you have network connectivity"
          );
        }
        break;
      default:
        alert("Unknown updates can't be sent, please contact the app admin");
    }
  };

  return (
    <div className="w-full shadow-lg m-0.25 p-0.5 text-lg flex flex-no-wrap items-start relative">
      <div className="flex flex-col w-full">
        <p>{requestType}</p>
        <p className="text-sm">{id}</p>
        <p className="text-xs">{update}</p>
      </div>
      <div className="inline-flex flex-no-wrap items-center absolute top-0 right-0 mt-0.5 mr-0.5">
        <FontAwesomeIcon
          icon={faRedo}
          size="lg"
          className="text-green-600 mr-0.5 cursor-pointer"
          onClick={retry}
        />
        <FontAwesomeIcon
          icon={faTimesCircle}
          size="lg"
          className="text-red-600 cursor-pointer"
          onClick={() => dispatch(removeDraft(props.id))}
        />
      </div>
    </div>
  );
};
