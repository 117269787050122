import { useCallback, useEffect, useState } from "react";
import WorkOrderItem from "../components/WorkOrders/WorkOrderItem";
import { setLoading } from "../features/jobSlice";
import { assetManagementConfig } from "../lib/Api";
import { useAppDispatch, useAppSelector } from "../lib/hooks";
import http from "../lib/Http";
import { WorkOrderModel } from "../lib/models/WorkOrder";
import { MAXIMO_USER_ID } from "../lib/Utility";

const WorkOrders: React.FC = () => {
  const role = useAppSelector((state) => state.role.value);
  const dispatch = useAppDispatch();
  const [workOrdersState, setWorkOrdersState] = useState<WorkOrderModel[]>([]);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const fetchWorkOrders = useCallback(async () => {
    const workOrderResults = await Promise.allSettled<any>(
      role?.crews.map((x) =>
        http.get(
          `/WorkOrderTask/?crewID=${x}&userID=${
            role.id.includes("@") ? MAXIMO_USER_ID : role.id
          }`,
          assetManagementConfig
        )
      )
    );

    let workOrders: WorkOrderModel[] = [];
    workOrderResults.forEach((wo) => {
      if (wo.status === "fulfilled" && wo.value.tag === "ok") {
        if (wo.value.data && wo.value.data.member.length > 0) {
          workOrders.push(...wo.value.data.member);
        }
      }
    });

    return workOrders;
  }, [role]);

  useEffect(() => {
    if (!role) {
      return;
    }
    (async () => {
      dispatch(setLoading(true));
      setWorkOrdersState(await fetchWorkOrders());
      dispatch(setLoading(false));
      setIsComplete(true);
    })();
  }, [dispatch, fetchWorkOrders, role]);

  return (
    <div className="text-black p-1 text-base md:w-3/4 mx-auto">
      {workOrdersState.length > 0
        ? workOrdersState.map((wo) => (
            <WorkOrderItem
              afterUpdate={async () =>
                setWorkOrdersState(await fetchWorkOrders())
              }
              data={wo}
              key={wo.wonum}
            />
          ))
        : isComplete && (
            <div className="mt-2 text-center">
              <span>No work orders found for your {`crew(s)`}</span>
            </div>
          )}
    </div>
  );
};

export default WorkOrders;
