import { AxiosRequestConfig } from "axios";
import http from "../Http";
import { DropdownOption } from "../../common/models/DropdownOption";

export const EASEMENT_BACKYARD = "Easement/Backyard";
export const SIDE_OF_ROAD = "Side of Road ";
export type DamageLocation = typeof EASEMENT_BACKYARD | typeof SIDE_OF_ROAD;

export const SUB_TRANSMISSION = "Sub-Transmission";
/**
 * @deprecated - "Distribution" was changed by "Primary"
 */
export const DISTRIBUTION = "Distribution";
export const PRIMARY = "Primary";
export const SECONDARY = "Secondary";
export const SERVICE = "Service";
export type Voltage =
  | typeof SUB_TRANSMISSION
  | typeof DISTRIBUTION
  | typeof SECONDARY
  | typeof PRIMARY
  | typeof SERVICE;

/**
 * @deprecated - "None" was changed by "No"
 */
export const NONE = "None";
/**
 * @deprecated - "Light" was changed by "Yes"
 */
export const LIGHT = "Light";
/**
 * @deprecated - "Heavy" was changed by "Yes"
 */
export const HEAVY = "Heavy";
/**
 * @deprecated - "Extreme" was changed by "Yes"
 */
export const EXTREME = "Extreme";
export const TT_YES = "Yes";
export const TT_NO = "No";
export type TreeTrim =
  | typeof NONE
  | typeof LIGHT
  | typeof HEAVY
  | typeof EXTREME
  | typeof TT_YES
  | typeof TT_NO;

export const STANDARD = "Standard";
export const POOR_FIELD_CONDITIONS = "Poor Field Conditions";
export const POOR_ROAD_CONDITIONS = "Poor Road Conditions";
export const SWAMP = "Swamp";
export const ISLAND = "Island";
export type SiteAccessibility =
  | typeof STANDARD
  | typeof POOR_FIELD_CONDITIONS
  | typeof POOR_ROAD_CONDITIONS
  | typeof SWAMP
  | typeof ISLAND;

export const SiteAccessibilityDescription = new Map<string, string>([
  [STANDARD, " Road right of way, truck has easy accessibility"],
  [
    POOR_FIELD_CONDITIONS,
    "Field includes damage/weather impact including overflow of water, debris, damaged equipment, minimal accessibility",
  ],
  [
    POOR_ROAD_CONDITIONS,
    "Overflow of water, tree debris, minimal accessibility on road",
  ],
  [SWAMP, "Marsh lands, not truck accessible"],
  [ISLAND, "Only one way to access damage"],
]);

export const getPoleTypeOptions = (): DropdownOption[] => [
  { label: "25/1/Pine", value: "25/1/Pine" },
  { label: "25/3/Pine", value: "25/3/Pine" },
  { label: "30/1/Pine", value: "30/1/Pine" },
  { label: "30/3/Pine", value: "30/3/Pine" },
  { label: "30/4/Pine", value: "30/4/Pine" },
  { label: "35/2/Pine", value: "35/2/Pine" },
  { label: "35/3/Cedar", value: "35/3/Cedar" },
  { label: "35/4/Pine", value: "35/4/Pine" },
  { label: "35/5/Pine", value: "35/5/Pine" },
  { label: "40/1/Pine", value: "40/1/Pine" },
  { label: "40/2/Pine", value: "40/2/Pine" },
  { label: "40/3/Pine", value: "40/3/Pine" },
  { label: "40/3/Cedar", value: "40/3/Cedar" },
  { label: "45/1/Pine", value: "45/1/Pine" },
  { label: "45/1/Cedar", value: "45/1/Cedar" },
  { label: "45/2/Pine", value: "45/2/Pine" },
  { label: "45/2/Cedar", value: "45/2/Cedar" },
  { label: "45/3/Pine", value: "45/3/Pine" },
  { label: "45/3/Cedar", value: "45/3/Cedar" },
  { label: "50/1/Pine", value: "50/1/Pine" },
  { label: "50/1/Cedar", value: "50/1/Cedar" },
  { label: "50/2/Pine", value: "50/2/Pine" },
  { label: "50/2/Cedar", value: "50/2/Cedar" },
  { label: "50/3/Pine", value: "50/3/Pine" },
  { label: "50/3/Cedar", value: "50/3/Cedar" },
  { label: "55/1/Pine", value: "55/1/Pine" },
  { label: "55/2/Pine", value: "55/2/Pine" },
  { label: "55/2/Cedar", value: "55/2/Cedar" },
  { label: "55/3/Pine", value: "55/3/Pine" },
  { label: "55/3/Cedar", value: "55/3/Cedar" },
  { label: "60/1/Pine", value: "60/1/Pine" },
  { label: "60/2/Pine", value: "60/2/Pine" },
  { label: "60/3/Pine", value: "60/3/Pine" },
  { label: "65/1/Pine", value: "65/1/Pine" },
  { label: "65/2/Pine", value: "65/2/Pine" },
  { label: "65/2/Cedar", value: "65/2/Cedar" },
  { label: "70/1/Fir", value: "70/1/Fir" },
  { label: "75/1/Fir", value: "75/1/Fir" },
  { label: "80/1/Fir", value: "80/1/Fir" },
  { label: "85/1/Fir", value: "85/1/Fir" },
  { label: "90/1/Fir", value: "90/1/Fir" },
  { label: "95/2/Fir", value: "95/2/Fir" },
  { label: "100/1/Fir", value: "100/1/Fir" },
  { label: "75/1/Cedar", value: "75/1/Cedar" },
  { label: "80/1/Cedar", value: "80/1/Cedar" },
  { label: "85/1/Cedar", value: "85/1/Cedar" },
  { label: "90/1/Cedar", value: "90/1/Cedar" },
  { label: "95/1/Cedar", value: "95/1/Cedar" },
  { label: "100/1/Cedar", value: "100/1/Cedar" },
  { label: "Other - specified in notes", value: "Other - specified in notes" },
];

export default interface DamageAssessment {
  jobId: string;
  circuit: string | null;
  okOnArrival: boolean;
  notes: string;
  numberOfBrokenPoles: number;
  arePolesOrdered?: boolean | null;
  brokenCrossArms: number;
  spansWireDown: number;
  isOilSpill?: boolean | null;
  voltage: Voltage[];
  damagedEquipmentCount?: number;
  damageLocation: DamageLocation | null;
  truckAccessible: boolean | null;
  treeTrim: TreeTrim | null;
  siteAccessibility: SiteAccessibility | null;
  siteAccessibilityNotes: string;
  brokenPolesLocations: BrokenPoleLocation[];
  crossArmsLocations: CrossArmsLocation[];
  photos: string[];
  createUser?: string;
  createDate?: string;
  submittedOn?: string;
  submittedBy?: string;
  hasPower: boolean | null;
  hasDamage: boolean | null;
}

export interface BrokenPoleLocation {
  location: Location;
  notes: string;
  poleInfo?: string;
  quantity?: number;
}

export interface CrossArmsLocation {
  location: Location;
  notes: string;
}

export interface DamageAssessmentLocationComment {
  location: Location;
  notes: string;
  link: string;
}

export interface Location {
  lat: number;
  long: number;
}

const headers = {
  headers: {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUB_KEY,
  },
};

const config: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_API_URL}/taskmanagement`,
  ...headers,
};

export const getDAForm = async (jobId: string) => {
  const params = {
    jobId,
  };
  const res = http.get<DamageAssessment>("/getDamageAssessment", {
    ...config,
    params,
  });
  return res;
};

export const uploadImage = async (image: File) => {
  const data = new FormData();
  data.append("file", image);
  const res = await http.post<{ message: string }>(
    "/uploadImage",
    data,
    config
  );
  return res;
};

export const saveDAForm = async (DAForm: DamageAssessment) => {
  const res = http.post("/saveDamageAssessment", DAForm, config);
  return res;
};

export const submitDAForm = async (DAForm: DamageAssessment) => {
  const res = http.post("/submitDamageAssessment", DAForm, config);
  return res;
};
