import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

const WarningBox: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="py-0.5 px-1 min-h-3.5 flex border bg-blue-100 border-gray-300 items-center text-black">
      <div className="w-1/6 flex-col align-start mr-1">
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </div>
      <div className="w-5/6 flex-col italic text-left ml-1">{message}</div>
    </div>
  );
};

export default WarningBox;
