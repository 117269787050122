import clsx from "clsx";

const TreeTrimFormColumn: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <div className={clsx("flex-col w-full lg:w-1/2", className)}>{children}</div>
);

export default TreeTrimFormColumn;
