import { Middleware } from "@reduxjs/toolkit";
import * as Util from "./Utility";

export const DRAFTS_LOCALSTORAGE_KEY = "OSA.DRAFTS";

export const localstorageMiddleware: Middleware =
  (api) => (next) => (action) => {
    if (action.type === "drafts/addDraft") {
      Util.pushToLocalStorageList(DRAFTS_LOCALSTORAGE_KEY, action.payload);
    } else if (action.type === "drafts/removeDraft") {
      Util.removeByIdFromLocalStorageList(
        DRAFTS_LOCALSTORAGE_KEY,
        action.payload
      );
    }
    const result = next(action);
    return result;
  };
