import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, Slide, toast } from "react-toastify";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import * as signalR from "@microsoft/signalr";
import {
  CrewAssignmentNotification,
  setNotificationRead,
  SignalRConfig,
  getCrewAssignmentNotifications,
} from "../../features/crewAssignmentNotificationsSlice";
import { useEffect, useState } from "react";
import { getDefaultSignalROptions, Role } from "../../lib/Utility";
import { negotiateSignalR } from "../../lib/api/crewAssignmentNotificationApi";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { AppDispatch } from "../../ReduxStore";
import CrewAssignmentNotificationMessage from "./CrewAssignmentNotificationMessage";
import { trackLocationData } from "../../lib/api/trackLocationApi";
import { setLoading } from "../../features/jobSlice";

const NotificationCloseButton = (props: {
  notification: CrewAssignmentNotification;
  role: Role;
}) => {
  const dispatch = useAppDispatch();

  const closeNotification = async () => {
    dispatch(setLoading(true));
    if (!props.notification.read) {
      await trackLocationData(
        "markNotificationAsRead",
        `${props.notification.jobId}-${props.notification.crewId}`,
        props.role
      );
      dispatch(setNotificationRead(props.notification));
    }
    toast.dismiss(props.notification.id);
    dispatch(setLoading(false));
  };

  return (
    <span onClick={closeNotification}>
      <FontAwesomeIcon
        icon={faTimes}
        className="text-black-200 text-lg mt-1 mr-0.5"
      />
    </span>
  );
};

const connectToNotificationsHub = async (
  role: Role,
  config: SignalRConfig,
  dispatch: AppDispatch
): Promise<signalR.HubConnection | null> => {
  try {
    console.log("Connecting to crew notifications hub");
    const connectionUrl = config.url;
    const accessToken = config.accessToken;

    const options = getDefaultSignalROptions(accessToken);

    const conn = new signalR.HubConnectionBuilder()
      .withUrl(connectionUrl, options)
      .withAutomaticReconnect()
      .build();

    conn.onclose(() => {
      console.log("Crew notifications hub connection is closing");
    });

    conn.on(role.id, (notification: CrewAssignmentNotification) => {
      toast(<CrewAssignmentNotificationMessage notification={notification} />, {
        toastId: notification.id,
        closeButton: (
          <NotificationCloseButton notification={notification} role={role} />
        ),
      });
      dispatch(getCrewAssignmentNotifications());
    });
    await conn.start();

    return conn;
  } catch (e) {
    console.log(e);
    return null;
  }
};

const CrewNotificationPopup = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);

  const [hubConnection, setHubConnection] =
    useState<signalR.HubConnection | null>(null);

  useEffect(() => {
    (async () => {
      if (!role) {
        return;
      }
      const response = await negotiateSignalR();
      if (response.tag !== "ok") {
        return;
      }

      const hubConn = await connectToNotificationsHub(
        role,
        response.data,
        dispatch
      );
      if (hubConn) {
        setHubConnection(hubConn);
      }
    })();

    return () => {
      if (hubConnection) {
        console.log("Stopping hub connection");
        hubConnection.stop();
      }
    };
  }, [role]);

  return (
    <>
      <div>
        <ToastContainer
          className="xs:min-w-full lg:min-w-3/4 mb-1"
          hideProgressBar={true}
          position="bottom-center"
          transition={Slide}
          closeButton={NotificationCloseButton}
          limit={5}
        />
      </div>
    </>
  );
};

export default CrewNotificationPopup;
